export default{
    "spei_title": "",
    "spei_amount": "",
    "spei_mxn": "MXN",
    "spei_id_type": "",
    "spei_rfc": "",
    "spei_curp": "",
    "spei_id_number": "",
    "spei_id_number_tips": "",
    "spei_name": "",
    "spei_name_tips": "",
    "spei_email": "",
    "spei_email_tips": "",
    "spei_confirm": "",
    "ramadan_title": "",
    "ramadan_2nd_title": "",
    "ramadan_2nd_gifttitle": "",
    "ramadan_2nd_gift1": "",
    "ramadan_2nd_gift2": "",
    "ramadan_2nd_gift3": "",
    "ramadan_2nd_gift4": "",
    "ramadan_2nd_gift_ticket": "",
    "ramadan_2nd_tab1": "",
    "ramadan_2nd_tab2": "",
    "ramadan_2nd_dailyranking": "",
    "ramadan_2nd_totalranking": "",
    "ramadan_2nd_reward_title1": "",
    "ramadan_2nd_reward_title2": "",
    "ramadan_2nd_reward_rank": "",
    "ramadan_2nd_reward_top1": "",
    "ramadan_2nd_reward_top2-3": "",
    "ramadan_2nd_reward_top4-10": "",
    "ramadan_2nd_daily_supporter_reward": "",
    "ramadan_2nd_daily_anchor_reward": "",
    "ramadan_2nd_total_supporter_top1": "",
    "ramadan_2nd_total_supporter_top2-3": "",
    "ramadan_2nd_total_supporter_top4-10": "",
    "ramadan_2nd_total_anchor_top1": "",
    "ramadan_2nd_total_anchor_top2-3": "",
    "ramadan_2nd_total_anchor_top4-10": "",
    "ramadan_3rd_title": "",
    "ramadan_3rd_pool": "",
    "ramadan_3rd_ticket": "",
    "ramadan_3rd_danger": "",
    "ramadan_3rd_ruletitle": "",
    "ramadan_3rd_rule1": "",
    "ramadan_3rd_rule2": "",
    "ramadan_3rd_rule3": "",
    "ramadan_signin_title": "",
    "ramadan_signin_time": "",
    "ramadan_signin_accumulated": "",
    "ramadan_signin_current": "",
    "ramadan_signin_days": "",
    "ramadan_signin_note": "",
    "ramadan_signin_expired": "",
    "ramadan_signin_received": "",
    "ramadan_signin_receivedok": "",
    "pk_vote_title": "",
    "pk_vote_body": "",
    "pk_vote_buttom": "",
    "pk_vote_buttom2": "",
    "pk_vote_note": "",
    "pk_vote_rule1": "",
    "pk_vote_rule2": "",
    "pk_vote_success": "",
    "valentine_title": "",
    "valentine_time_startin": "Event starts in",
    "valentine_time_ended": "Event ended",
    "valentine_time_endin": "",
    "valentine_time_days": "",
    "valentine_time_hours": "",
    "valentine_time_minutes": "",
    "valentine_time_seconds": "",
    "valentine_gift": "",
    "valentine_gift_1": "",
    "valentine_gift_2": "",
    "valentine_gift_3": "",
    "valentine_gift_4": "",
    "valentine_tab1": "",
    "valentine_tab2": "",
    "valentine_tab1_honeytrip": "",
    "valentine_rule_title": "",
    "valentine_tab1_honeytrip_rule1": "",
    "valentine_tab1_honeytrip_rule2": "",
    "valentine_tab1_honeytrip_switchcp": "",
    "valentine_tab1_romantictrip": "",
    "valentine_trip_intro": "",
    "valentine_trip_step1": "",
    "valentine_trip_step2": "",
    "valentine_trip_step3": "",
    "valentine_trip_step4": "",
    "valentine_trip_step5": "",
    "valentine_trip_target": "",
    "valentine_preview": "",
    "valentine_preview_claim": "",
    "valentine_successful": "",
    "valentine_preview_claimed": "",
    "valentine_preview_unlocked": "",
    "valentine_tab2_rule1": "",
    "valentine_tab2_rule2": "",
    "valentine_tab3": "",
    "valentine_tab4": "",
    "valentine_tab3_timecount": "",
    "valentine_tab3_finished": "",
    "valentine_rewards_button": "",
    "valentine_rewards_romantictrip_title1": "",
    "valentine_rewards_romantictrip_title2": "",
    "valentine_frame1": "",
    "valentine_frame1_female": "",
    "valentine_chatbubble": "",
    "valentine_entranceeffect": "",
    "valentine_frame2": "",
    "valentine_frame2_female": "",
    "valentine_7day": "",
    "valentine_15day": "",
    "valentine_20day": "",
    "valentine_reward_male": "",
    "valentine_reward_female": "",
    "valentine_cpwall": "",
    "valentine_cpidentification": "",
    "valentine_dailycp_reward": "",
    "valentine_dailycp_maleuser": "",
    "valentine_dailycp_femaleuser": "",
    "valentine_overall_rank": "",
    "valentine_overall_top1": "",
    "valentine_overall_top2": "",
    "valentine_overall_top3": "",
    "valentine_overall_top4-10": "",
    "valentine_overall_dollar": "",
    "valentine_overall_roombg": "",
    "valentine_overall_supporter": "",
    "valentine_overall_supporter1": "",
    "valentine_overall_supporter2": "",
    "valentine_overall_supporter3": "",
    "valentine_overall_backpackgift_artr": "",
    "valentine_overall_backpackgift_espt": "",
    "valentine_overall_giftname": "",
    "valentine_10days": "",
    "valentine_cpwall_ar": "",
    "valentine_cpwall_trespt": "",
    "valentine_reward_send": "",
    "legend_title": "",
    "legend_tab1": "",
    "legend_tab2": "",
    "legend_tab3": "",
    "legend_tab4": "",
    "legend_new_tab1": "",
    "legend_new_tab2": "",
    "legend_tab_title_rank": "",
    "legend_tab_title_agencyid": "",
    "legend_tab_title_agencyname": "",
    "legend_tab_title_coins": "",
    "legend_tab_title_rewards": "",
    "legend_tab_title_target": "",
    "legend_rule_button": "",
    "legend_rule_row1": "",
    "legend_rule_row2": "",
    "legend_rule_row3": "",
    "legend_rule_row4": "",
    "legend_rule_row5": "",
    "legend_rule_row6": "",
    "legend_rule_row7": "",
    "legend_rule_row8": "",
    "legend_rule_row9": "",
    "legend_rule_vipplan": "",
    "legend_rule_vipplan_title1": "",
    "legend_rule_vipplan_title2": "",
    "legend_rule_vipplan_title3": "",
    "legend_rule_vipplan_title4": "",
    "legend_rule_vipplan_note": "",
    "legend_reward_button": "",
    "legend_reward_title1": "",
    "legend_reward_title2": "",
    "legend_reward_title3": "",
    "legend_reward_title4": "",
    "legend_reward_note1": "",
    "legend_reward_note2": "",
    "legend_reward_dollar_1": "",
    "legend_reward_dollar_2": "",
    "legend_reward_dollar_3": "",
    "legend_reward_dollar_4": "",
    "legend_reward_dollar_5": "",
    "legend_reward_diamonds_1": "",
    "legend_reward_diamonds_2": "",
    "legend_reward_diamonds_3": "",
    "legend_reward_diamonds_4": "",
    "legend_reward_diamonds_5": "",
    "legend_reward_diamonds_6": "",
    "legend_reward_coins_1": "",
    "legend_reward_coins_2": "",
    "legend_reward_coins_3": "",
    "legend_reward_coins_4": "",
    "legend_reward_coins_5": "",
    "legend_kdiamonds": "",
    "legend_mcoins": "",
    "legend_categoryc_top7reward": "",
    "legend_freevip_new": "",
    "legend_vip4s": "",
    "legend_vip5s": "",
    "legend_vip6s": "",
    "legend_appsponsor": "",
    "legend_reward_link1": "",
    "legend_reward_link2": "",
    "xmas_title": "",
    "xmas_gift1": "",
    "xmas_gift2": "",
    "xmas_gift3": "",
    "xmas_gift4": "",
    "xmas_espt_host_top1": "",
    "xmas_espt_host_top2-3": "",
    "xmas_espt_host_top4-10": "",
    "xmas_espt_supporter_top1": "",
    "xmas_espt_supporter_top2-3": "",
    "xmas_espt_supporter_top4-10": "",
    "xmas_tr_host_top1": "",
    "xmas_tr_host_top2-3": "",
    "xmas_tr_host_top4-10": "",
    "xmas_tr_supporter_top1": "",
    "xmas_tr_supporter_top2-3": "",
    "xmas_tr_supporter_top4-10": "",
    "xmas_ar_host_top1": "",
    "xmas_ar_host_top2-3": "",
    "xmas_ar_host_top4-10": "",
    "xmas_ar_supporter_top1": "",
    "xmas_ar_supporter_top2-3": "",
    "xmas_ar_supporter_top4-10": "",
    "xmas_rankingcountry_espt": "",
    "xmas_rankingcountry_tr": "",
    "xmas_rankingcountry_ar": "",
    "roomcup_title": "",
    "roomcup_Room ID ": "",
    "roomcup_countdown": "",
    "roomcup_day": "",
    "roomcup_hour": "",
    "roomcup_minutes": "",
    "roomcup_seconds": "",
    "roomcup_coin_recieve": "",
    "roomcup_target_icon": "",
    "roomcup_reward_icon": "",
    "roomcup_roomreward": "",
    "roomcup_roomreward_lasttarget": "",
    "roomcup_roomreward_lastreward": "",
    "roomcup_claim_icon": "",
    "roomcup_claimed_icon": "",
    "roomcup_doubletargetcontest": "",
    "roomcup_ratio": "",
    "roomcup_doubletarget": "",
    "roomcup_doubletarget_reward": "",
    "roomcup_noroom": "",
    "roomcup_noarea": "",
    "roomcup_rule_icon": "",
    "roomcup_rule_row1": "",
    "roomcup_rule_row2": "",
    "roomcup_rule_row3": "",
    "roomcup_rule_row4": "",
    "roomcup_coins": "",
    "roomcup_diamonds": "",
    "roomcup_notachieved": "",
    "roomcup_successful": "",
    "budang_title": "",
    "budang_beforestart": "",
    "budang_inprogress": "",
    "budang_ended": "",
    "budang_days": "",
    "budang_hours": "",
    "budang_minutes ": "",
    "budang_seconds": "",
    "budang_open": "",
    "budang_open_cannot": "",
    "budang_open_once": "",
    "budang_open_ended": "",
    "budang_myagency": "",
    "budang_mytarget": "",
    "budang_distanceaway": "",
    "budang_distanceaway_2": "",
    "budang_highestlevel": "",
    "budang_rewardname": "",
    "budang_reward1": "",
    "budang_reward2": "",
    "budang_reward3": "",
    "budang_reward_rule": "",
    "budang_rule_button": "",
    "budang_rule_row1": "",
    "budang_rule_row1_202501": "",
    "budang_rule_row1_202502": "",
    "budang_rule_row2": "",
    "budang_rule_row3": "",
    "budang_rule_row4": "",
    "resellerNotTransaction": "該用戶不屬於你的可交易範圍，無法完成交易",
    "tingzhan_title_tr": "",
    "tingzhan_title": "Party Glory",
    "tingzhan_time_title": "Event Time",
    "tingzhan_time": "UTC+8 03.24 00:00 - 04.06 24:00",
    "tingzhan_rule_buttom": "Rules & Rewards",
    "tingzhan_tab1": "Qualifying ",
    "tingzhan_tab1_time": "03.24-03.25",
    "tingzhan_tab1_rule_row1": "Best 16",
    "tingzhan_tab1_rule_row2": "The ranking based on the cumulative number of coins sent of the event gifts in the room, the number of coins is the same, the first to arrive first.",
    "tingzhan_tab1_rule_row3": "In this round, TOP1-8 rooms enter the gold group, and 9-16 rooms enter the silver group.",
    "tingzhan_tab1_rule_row4": "UTC+8 03.24 00:00 - 03.25 24:00",
    "tingzhan_tab_gold": "Gold Group",
    "tingzhan_tab_silver": "Silver Group",
    "tingzhan_tab2": "Promotion ",
    "tingzhan_tab2_time": "03.27-03.29",
    "tingzhan_tab2_goldrule_row1": "Best 6",
    "tingzhan_tab2_goldrule_row2": "The ranking based on the cumulative number of coins sent of the event gifts in the room, the number of coins is the same, the first to arrive first. The 7th and 8th place rooms will fall into the silver group.",
    "tingzhan_tab1_goldrule_row3": "UTC+8 03.27 00:00 - 03.29 24:00",
    "tingzhan_tab2_silverrule_row1": "Best 4",
    "tingzhan_tab2_silverrule_row2": "The ranking based on the cumulative number of coins sent of the event gifts in the room, the number of coins is the same, the first to arrive first. Rooms ranked 5-8 will be eliminated",
    "tingzhan_tab2_silverrule_row3": "UTC+8 03.27 00:00 - 03.29 24:00",
    "tingzhan_tab3": "PK ",
    "tingzhan_tab3_time": "03.31-04.02",
    "tingzhan_tab3_goldrule_row1": "Best 3-PK",
    "tingzhan_tab3_goldrule_row2": "1v1 PK with accumulated coins for 3 days. The winner advances, the loser advances to the silver group.",
    "tingzhan_tab3_goldrule_row3": "There is a quota for resurrection in this round, and the loser with the most coins will be resurrected.",
    "tingzhan_tab3_goldrule_row4": "UTC+8 03.31 00:00 - 04.02 24:00",
    "tingzhan_tab3_silverrule_row1": "Best 3-PK",
    "tingzhan_tab3_silverrule_row2": "1v1PK with accumulated coins for 3 days. The loser will be eliminated, the number of coins is the same, the first to arrive first.",
    "tingzhan_tab3_silverrule_row3": "UTC+8 03.31 00:00 - 04.02 24:00",
    "tingzhan_tab4": "Final ",
    "tingzhan_tab4_time": "04.04-04.06",
    "tingzhan_tab4_rule_row1": "Final",
    "tingzhan_tab4_rule_row2": "The ranking based on the cumulative number of coins sent of the event gifts in the room, the number of coins is the same, the first to arrive first.",
    "tingzhan_tab4_rule_row3": "UTC+8 04.04 00:00 - 04.06 24:00",
    "tingzhan_tab4_countdown": "Final Countdown",
    "tingzhan_danger": "Danger",
    "tingzhan_win": "Win",
    "tingzhan_out": "Out",
    "tingzhan_Revive": "Revive",
    "tingzhan_Silver": "Silver",
    "tingzhan_rule_back": "Back",
    "tingzhan_rule_tab1": "Event Intro",
    "tingzhan_rule_tab2": "Rewards",
    "tingzhan_rule_tab1_title": "Event Gifts",
    "tingzhan_rule_luvkygift1": "?",
    "tingzhan_rule_gift2": "Rock Kitty",
    "tingzhan_rule_gift3": "Falconer",
    "tingzhan_rule_gift4": "Treasure Map",
    "tingzhan_rule_gift4_name": "Sponsorship (TOP1) * 10 days",
    "tingzhan_gift_price": "Price",
    "tingzhan_tab2_banner_title": "1.5 Times Exp.!",
    "tingzhan_tab2_banner_body": "Sending event gifts during the event and you will get 1.5 Times experience points.",
    "tingzhan_tab2_roomreward": "Room Rewards",
    "tingzhan_tab2_supporterreward": "Supporter Rewards",
    "tingzhan_tab2_reward": "Rewards",
    "tingzhan_tab2_roombg": "Moving Bg",
    "tingzhan_roomcover": "Room Cover",
    "tingzhan_1stplace": "1st Place",
    "tingzhan_2ndplace": "2nd Place",
    "tingzhan_3rdplace": "3rd Place",
    "tingzhan_tab2_usd": "USD",
    "tingzhan_500usd": "500 USD",
    "tingzhan_400usd": "400 USD",
    "tingzhan_300usd": "300 USD",
    "tingzhan_200usd": "200 USD",
    "tingzhan_100usd": "100 USD",
    "tingzhan_tab2_avatar": "Avatar Frame",
    "tingzhan_tab2_entrance": "Entrance Effect",
    "tingzhan_tab2_specialid": "Special ID",
    "tingzhan_tab2_sponsorship": "Event Gift Sponsorship",
    "tingzhan_supporter_1stplace": "1st Place Room Supporter",
    "tingzhan_supporter_2ndplace": "2nd Place Room Supporters",
    "tingzhan_supporter_3rdplace": "3rd Place Room Supporters",
    "tingzhan_30days": "30 days",
    "tingzhan_15days": "15 days",
    "tingzhan_7days": "7 days",
    "tingzhan_3days": "3 days",
    "tingzhan_tab2_specialid_body1": "Random 2/3/4 -digital ID",
    "tingzhan_tab2_specialid_body2": "Special ID: 50/500/5000",
    "tingzhan_tab2_sponsorship_body": "Sponsorship (TOP1) * 10 days",
    "tingzhan_tab2_other": "Aside from experience points, event rewards will be sent in 3 working days to the user's backpack.",
    "tingzhan_notstart": "Event hasn't started",
    "turkeyCumhuriyet": "Cumhuriyet Bayramı Event",
    "turkeyDistance": "Event starts in",
    "turkeyStart": "Event ends in",
    "turkeyEnd": "Event ended",
    "turkeyEventGifts": "Event Gifts",
    "turkeyCumhuriyetGift": "Cumhuriyet Bayramı",
    "turkey80": "80k coins",
    "turkeyDays": "days",
    "turkeyHours": "hours",
    "turkeyMin": "min",
    "turkeyToStart": "to Start",
    "turkeyTurkey": "Turkey",
    "turkeyTop1": "Top 1 ",
    "turkey500": "$500",
    "turkeyTop2_3": "Top2-3 ",
    "turkey300": "300k diamonds",
    "turkeyTop4_10": "Top4-10",
    "turkey100": " 100k diamonds",
    "turkey200": "$200",
    "turkey200k": "200k coins",
    "turkeyTop4_10_2": "Top 20 rooms",
    "turkeySupporters": "Supporters",
    "turkeyAnchors": "Anchors",
    "turkeyParties": "Parties",
    "turkeyRanking": "Ranking countries: Turkey & Azerbaijan",
    "turkeyRanking2": "Ranking countries: Non - Turkey or Azerbaijan",
    "turkeytop1newanchor": "200k coins",
    "turkeytop2-3newanchor": "50k coins",
    "turkeytop4-10newanchor": "20k coins",
    "turkeytop1newsupport": "Noble 6*30 days",
    "turkeytop2-3newsupport": "Noble 5*30 days",
    "turkeytop4-10newsupport": "Noble 4*30 days",
    "wealthNeedNextLevel": "經驗即可陞級至下一等級",
    "pkTouramentPro": "PK Tourament Pro",
    "pkTop1": "Top1",
    "pkTop2": "Top2",
    "pkTop3": "Top3",
    "pk1000Usd": "1000 USD",
    "pk500Usd": "500 USD",
    "pk300Usd": "300 USD",
    "pkRewards": "Rewards for each Qualifying Round",
    "pkRewards2": "Rewards for each Promotion Round",
    "pk50kCoins": "50k Coins Target",
    "pk100kCoins": "100k Coins Target",
    "pkNote": "Note: for qualifying round and promotion round rewards, if the diamonds spent in the specific PK is less than the reward amount. The reward will not be sent but the PK result is still counted",
    "pk200Usd": "200 USD",
    "pk30Day": "Frame*30 Days",
    "pk15Day": "Frame*15 Days",
    "pk7Day": "Frame*7 Days",
    "pkNote2": "Note：Support in all PK Rounds is calculated. Lucky gifts are counted 10% Treasure Boxes are counted based on the gift won amount. ",
    "pkTourament": "Tourament",
    "pkToday": "Today Schedule",
    "pkSupporter": "Supporter",
    "pkQualifying": "Qualifying Round",
    "pkQualifying1": "Qualifying Round1",
    "pkQualifying2": "Qualifying Round2",
    "pkPromotion": "Promotion Round",
    "pkSummit": "Summit Series",
    "pk32Finals": "1/32-Finals",
    "pk16Finals": "1/16-Finals",
    "pk8Finals": "1/8-Finals",
    "pkQuater": "Quater-Finals",
    "pkSemi": "Semi-Finals",
    "pkFinal": "Final",
    "pk3rd": "3rd Place",
    "pk_host_reward_top1": "",
    "pk_host_reward_top1_b": "",
    "pk_host_reward_top2": "",
    "pk_host_reward_top2_b": "",
    "pk_host_reward_top3": "",
    "pk_host_reward_top3_b": "",
    "pk_supporter_reward_top1": "",
    "pk_supporter_reward_top1_b": "",
    "pk_supporter_reward_top2": "",
    "pk_supporter_reward_top2_b": "",
    "pk_supporter_reward_top3": "",
    "pk_supporter_reward_top3_b": "",
    "pk_host_rule_row1": "",
    "pk_host_rule_row2": "",
    "pk_host_rule_note": "",
    "pk_supporter_rule_note": "",
    "pk_es_rule1": "",
    "pk_es_rule2": "",
    "pk_es_rule3": "",
    "pk_es_host_top1": "",
    "pk_es_host_top2": "",
    "pk_es_host_top3": "",
    "pk_es_supporter_top1": "",
    "pk_es_supporter_top2": "",
    "pk_es_supporter_top3": "",
    "pk_es_15day": "",
    "pk_es_10day": "",
    "pk_es_5day": "",
    "dreamMidsummer": "Midsummer Night's Dream",
    "dreamSend": "Send exclusive gifts to seal your midsummer love",
    "dreamRank": "Sending the following event gifts during the event. The higher the ranking, the richer the prizes. Come and participate to get generous rewards!",
    "dreamPreview": "Rewards Preview",
    "dreamSupporter": "Supporter",
    "dreamHost": "Host",
    "dreamTop1": "Top1",
    "dreamTop2": "Top2",
    "dreamTop3": "Top3",
    "dreamTop4_10": "Top4-10",
    "dreamRankings": "Rankings",
    "dreamRose": "Rose Throne",
    "dreamRoseBasket": "Rose Basket",
    "dreamButterfly": "Butterfly Wonderland",
    "dreamLovers": "Everlasting Love",
    "dreamFlower": "How Much Love",
    "dream15d": "*15d",
    "dreamCentury": "Glorious Century",
    "dream7": "*7",
    "dream7d": "*7d",
    "dreamHeart": "Heart Flying",
    "dreamSummerLove": "Summer Love",
    "dreamFancy": "Fancy Unicorn",
    "dreamRules": "Event Rules",
    "dreamTime": "Event time",
    "dreamPlay": "How to play",
    "dreamUsersTop": "During the event, via sending specific gifts, users are ranked based on the number of diamonds given and coins received. Users with top rankings can receive rewards.",
    "dreamRewards": "Rewards for Rose Basket and Butterfly Wonderland Ranking",
    "dreamFrame": "Rose Throne Frame",
    "dreamCenturyGift": "Glorious Century Gift",
    "dreamEntrance": "Heart Flying Entrance",
    "dream5": "*5",
    "dream5d": "*5d",
    "dream3": "*3",
    "dream3d": "*3d",
    "dreamDiamonds": "Diamonds",
    "dream25000": "*25000",
    "dreamVerification": "Summer Love Verification",
    "dreamUnicorn": "Fancy Unicorn",
    "dreamEverlasting": "Rewards for Everlasting Love and How Much Love Rank",
    "dreamCoins": "Coins",
    "dream100000": "*100000",
    "dream50000": "*50000",
    "dream5000": "*5000",
    "dreamHostBottom": "Coins counted in the target",
    "zainCashTitle": "",
    "zainTotalPay": "",
    "zainIqd": "",
    "zainName": "",
    "zainNameTips": "",
    "zainPhoneNumber": "",
    "zainPhoneNumberTips": "",
    "zainSubmit": "",
    "zainNumberErrorTips": "",
    "zainNameNotNull": "",
    "luckyEventStartsIn": "活動開始於",
    "luckyEventEndsIn": "",
    "luckyEventInstruction": "活動說明",
    "luckySendingFollowing": "贈送下列禮物可以獲得小綿羊",
    "luckyRoom": "房間",
    "luckyRoomTips": "",
    "luckyGifter": "送禮人",
    "luckyAnchors": "",
    "luckyGoldenBoot": "",
    "luckyFootball": "",
    "luckyDefensiveMaster": "",
    "luckyTheWorldCup": "",
    "luckyNoOne": "列表為空~",
    "luckyRules": "規則",
    "luckyHowTo": "如何進入排行榜?",
    "luckyHowToGetI": "如何獲得",
    "luckyTheGifts": "活動贈送的禮物按照鉆石累計數量排名，活動領取的禮物按照金幣累計數量排名。",
    "luckyRewards": "獎勵",
    "luckyRoomReward": "房間獎勵",
    "luckyGifterRewards": "送禮人獎勵",
    "luckyBlessingCard": "祈禱卡",
    "luckyTop_1": "積分 <span>100000</span>",
    "luckyTop_2": "頭像框x<span>10<span>天",
    "luckyTop_3": "座駕 x<span>10</span>天",
    "luckyTop_1_1": "",
    "luckyTop_2_1": "",
    "luckyTop_3_1": "",
    "luckyTop_4": "專屬橫幅",
    "luckyEventEnd": "活動結束",
    "anchorActive": {
        "activeList": "活躍榜",
        "activeListRewards": "活躍榜獎勵：",
        "back": "返回",
        "banner10": "10天定制Banner",
        "capture": "成為焦點，贏得獎勵",
        "charming": "魅力榜獎勵：",
        "charmingList": "魅力榜",
        "days": "日",
        "entrance10": "10天活動專屬座駕",
        "entrance5": "5天活動專屬座駕",
        "entrance7": "7天活動專屬座駕",
        "frame10": "10天活動專屬頭像框",
        "frame5": "5天活動專屬頭像框",
        "frame7": "7天活動專屬頭像框",
        "hours": "時",
        "laseWeek": "上周",
        "laseWeekRank": "上周榜單",
        "listOne": "榜單壹：活躍榜",
        "listOneMsg1": "主播通過1v1獲得的points收入=相應活躍值(包含主播回消息，語音視頻通話等，除收禮以外的所有積分)",
        "listOneMsg2": "語音房上麥時長/分鐘=10積分",
        "listTwo": "榜單二：魅力榜",
        "listTwoMsg1": "1v1收禮鉆石數量=相應魅力值",
        "listTwoMsg2": "語音房收禮鉆石數量=雙倍魅力值",
        "listTwoMsg3": "幸運禮物10%折算魅力值",
        "minutes": "分",
        "noRanking": "暫無榜單數據",
        "points100000": "10k積分",
        "points30k": "30k積分",
        "points50000": "5k積分",
        "points50k": "50k積分",
        "rank": "榜單",
        "rewards": "獎勵",
        "rules": "規則",
        "seconds": "秒",
        "top1": "TOP1",
        "top2": "TOP2",
        "top3": "TOP3"
    },
    "bindAccount": {
        "Bank_Transfer": "銀行轉賬",
        "Bind_Account": "綁定賬號",
        "limit": {
            "account_name_tips": "用英文填寫全名",
            "bank_card_number": "填寫銀行卡號，例如：10000000000",
            "bankCode": "填寫銀行Swift Code，例如：CMBCCNBS",
            "bankName": "填寫銀行英文名稱，例如：State Bank of India",
            "City": "用英語填寫你的城市",
            "country_code": "填寫大寫英文國家代碼，例如：IN",
            "name_tips": "用英文填寫名字",
            "payoneer_email": "請在payoneer中找到你綁定的郵箱",
            "payoneerID": "請在你的Payoneer賬戶中找到你的ID，例如：47289645466",
            "paypal_email": "輸入綁定Paypal賬戶的郵箱",
            "State_or_Province": "用英文填寫州或省",
            "Street": "用英文填寫街道",
            "StrePostal_Codeet": "填寫地址的郵政編碼"
        },
        "Payment_Method": "收款方式",
        "placeholder": {
            "bank_account_name": "收款人銀行賬戶名稱",
            "bank_card_number": "收款銀行賬號",
            "bankCode": "收款銀行代碼",
            "bankName": "收款銀行英文名稱",
            "Beneficiary_country_code": "收款人國家代碼",
            "City": "收款人地址 - 城市",
            "country": "銀行卡國家代碼",
            "Payee_First_name": "收款人名",
            "Payee_Last_Name": "收款人姓",
            "payoneer_email": "填寫你的郵箱",
            "payoneerID": "填寫你的Payoneer ID",
            "paypal_email": "填寫你的賬號郵箱",
            "Postal_Code": "收款人地址 - 郵政編碼",
            "State_or_Province": "收款人地址 - 州或省",
            "Street": "街道"
        },
        "popup": {
            "Bound_account": "綁定賬戶：",
            "cancel": "取消",
            "complete": "完成",
            "confirm": "確認",
            "country": "銀行卡所在國家",
            "payment_method": "收款方式",
            "payoneer_email": "Payoneer賬號郵箱"
        },
        "title": {
            "bank_account_name": "收款人的銀行賬戶名稱",
            "bank_card_number": "收款人銀行卡號",
            "bankCode": "銀行卡國家代碼",
            "bankName": "收款銀行名稱",
            "country": "銀行卡所在國家",
            "Payee_Address": "收款人地址",
            "Payee_First_name": "收款人名",
            "Payee_Last_Name": "收款人姓",
            "payoneer_email": "Payoneer賬號郵箱",
            "payoneerID": "Payoneer ID",
            "paypal_email": "paypal賬號郵箱"
        },
        "toast": {
            "bank_account_name": "收款人的銀行賬戶名稱不能為空",
            "bank_card_number": "收款人銀行卡號不能為空",
            "bankCode": "收款銀行代碼不能為空",
            "bankName": "收款銀行名稱不能為空",
            "Beneficiary_country_code": "收款人地址國家不能為空",
            "City": "收款人地址城市不能為空",
            "country": "銀行卡所在國家不能為空",
            "emailyz": "請輸入有效且合法的電子郵件地址！ ",
            "Payee_First_name": "收款人名不能為空",
            "Payee_Last_Name": "收款人姓不能為空",
            "payoneer_account": "請再次檢查確認，以免造成財產損失",
            "payoneer_email": "Payoneer郵箱不能為空",
            "payoneerID": "Payoneer ID 不能為空",
            "paypal_email": "Payoneer 郵箱 不能為空",
            "State_or_Province": "收款人地址州或省不能為空",
            "Street": "收款人地址街道不能為空"
        }
    },
    "diamondDescription": {
        "title": "VIP&SVIP規則",
        "txt1": "1. VIP和SVIP可以同時開啟；",
        "txt2": "2. VIP用戶每日可領取免費消息券；",
        "txt3": "3. SVIP用戶每日可領取免費鑽石；",
        "txt4": "4. SVIP用戶每日可完成返鑽任務",
        "txt5": "5. SVIP返鑽任務每日更新需當日領取，VIP每日消息券，需要當日領取，過期不累計；",
        "txt6": "6. 遊戲的消費將不計入消費鑽石數量。"
    },
    "eventRewards": {
        "player": "每日活躍玩家人數",
        "proportion": "房間獲勝時的返獎百分比",
        "title": "活動返獎",
        "txt1": "1. 每天凌晨0點（UTC+8）計算昨日的數據。",
        "txt2": "2. 根據房間內參與的人數和房間內實際消耗的鑽石（用戶下注鑽石 - 用戶獲得鑽石）進行返獎。",
        "txt3": "3. 每週一凌晨0點（UTC+8）計算上週數據，獎勵將在3日內下發。",
        "txt4": "4. 返獎比例：",
        "txt5": "5. 活躍玩家定義：當日累計下註超過1000鉆。"
    },
    "failed": "操作失敗！",
    "idolHelp": {
        "content1": "Layla Idol是Layla App給受歡迎的女孩頒發的特殊標識。成為Layla Idol將獲得多種特權。包括特殊的標識，更多的曝光，更多的匹配消息。",
        "content2_1": "1. 首先要通過眞人認證的審核，其次，聊天分要達到80分。",
        "content2_2": "2. 當日達到活躍條件，聊天分會在次日4點(UTC+8)根據過去7日的數據計算聊天分。",
        "content2_3": "3. 當日未達到活躍條件，次日聊天分將歸0。再次活躍將重新累積計算聊天分。",
        "content2_4": "4. 當日的活躍條件為：",
        "content2_4_1": "a.過去7日在綫時長>",
        "content2_4_2": "b.過去7日主動聊天人數>",
        "content2_4_3": "c.昨日登錄過App。",
        "content3_1": "聊天分是根據你在私聊場景的受歡迎程度計算出來的。這包括：",
        "content3_2": "1. 曝光。有多少人在首頁、動態、資料頁看到過你。",
        "content3_3": "2. 在綫時長。你在App內待了多少時長。",
        "content3_4": "3. 活躍親密度數。當日有多少親密度大於3的用戶給你發送過信息。",
        "content3_5": "4. 消息被回復率。你發出消息，有多少被回復了。",
        "content3_6": "5. 主動聊天人數。你給多少个用戶打招呼或者發消息。",
        "content3_7": "6. 有效回復率。你在有效時間內回復他人消息的比率。",
        "content3_8": "7. 聊天人均耗鑽。和你聊天的人消耗了多少鑽石，包括聊天和1v1的送禮。",
        "content3_9": "8. 聊天耗鑽人數。有多少人在和你聊天時消耗過鑽石，包括聊天和1v1送禮。",
        "content4_1": "你可以在聊天分詳情頁，看到你各項數據的統計値，以及和Layla Idol的對比情況。",
        "content4_2": "點擊對應項目的提示，會有提昇對應數値的引導。",
        "header1": "什麽是Layla Idol",
        "header2": "如何成為Layla Idol",
        "header3": "什麽是聊天分",
        "header4": "如何提高聊天分",
        "minute": "分鍾。",
        "people": "人。",
        "title": "Layla Idol 幫助"
    },
    "inviteCash": {
        "COPY": "複製",
        "Copy_successfully": "複製成功",
        "title": "邀請賺金"
    },
    "Level": {
        "avatarFrame": "頭像框",
        "badgeReward": "勳章獎勵",
        "charmLevel": "魅力等級",
        "currentExp": "當前經驗值",
        "entranceEffect": "入場座駕",
        "familyLevel": "家族等級",
        "familyLeveldata": [
            "等級",
            "所需貢獻值",
            "成員人數",
            "身份"
        ],
        "gradePrivileges": "等級特權",
        "identity": [
            "副族長",
            "長老"
        ],
        "personLevel": "個人等級",
        "stillNeed": "需要",
        "title": "等級",
        "upgrade": "怎樣升級？",
        "upgradebox": {
            "charm": [
                {
                    "title": "收禮",
                    "value": "1金幣=1魅力值"
                }
            ],
            "family": [
                {
                    "title": "家族任務",
                    "value": "領取家族貢獻值"
                }
            ],
            "person": [
                {
                    "title": "送禮",
                    "value": "1鑽石 = 1個人經驗"
                },
                {
                    "title": "完成任務",
                    "value": "領取個人經驗"
                }
            ],
            "wealth": [
                {
                    "title": "送禮",
                    "value": "1鑽石 = 1財富經驗"
                },
                {
                    "title": "完成任務",
                    "value": "領取財富經驗"
                }
            ]
        },
        "wealthLevel": "財富等級"
    },
    "nation": {
        "ae": "阿拉伯聯合酋長國",
        "ao": "安哥拉",
        "ar": "阿根廷",
        "at": "奧地利",
        "au": "澳大利亞",
        "bd": "孟加拉國",
        "bh": "巴林",
        "br": "巴西",
        "ca": "加拿大",
        "cl": "智利",
        "cn": "中國大陸",
        "de": "德國",
        "dz": "阿爾及利亞",
        "eg": "埃及",
        "es": "西班牙",
        "fr": "法國",
        "gb": "英國",
        "hk": "中國香港",
        "il": "以色列",
        "iq": "伊拉克",
        "ir": "伊朗",
        "it": "意大利",
        "jo": "約旦",
        "jp": "日本",
        "kw": "科威特",
        "lb": "黎巴嫩",
        "lv": "拉脫維亞",
        "ly": "利比亞",
        "ma": "摩洛哥",
        "mx": "墨西哥",
        "my": "馬來西亞",
        "nl": "荷蘭",
        "om": "阿曼",
        "ph": "菲律賓",
        "pk": "巴基斯坦",
        "ps": "巴勒斯坦",
        "pt": "葡萄牙",
        "qa": "卡塔爾",
        "ru": "俄羅斯",
        "sa": "沙特阿拉伯",
        "sd": "蘇丹",
        "sy": "敘利亞",
        "tn": "突尼斯",
        "tr": "土耳其",
        "tw": "台灣 ",
        "us": "美國",
        "ye": "也門"
    },
    "nobilityLevel": {
        "diamond": "鑽石消耗",
        "head": "貴族等級",
        "title": "貴族等級規則",
        "txt1": "1. 每個自然月1號0點開始（UTC+8），系統將自動統計使用者經驗值，根據下列表格經驗值重新配對使用者的貴族等級。",
        "txt2": "2. 每儲值1鑽石=1經驗值。",
        "txt3": "3. 經驗值計算=當月經驗值+上月經驗值。",
        "txt4": "Ⅰ. 用戶在獲得對應貴族等級後，貴族等級和特權會保留至下個月月末，例如：10月17日用回達到Lv.4，則Lv.4等級會延續至11月30日。",
        "txt5": "Ⅱ. 在當前自然月周期內，若用戶在獲得較低的貴族等級後，再達到更高的等級，則直接獲得更高級別的貴族等級，相應的貴族等級會保留至下個月月末，例如：在10月17日用戶在達到Lv.2後，在10月19日VIP等級達到Lv.4，則直接獲得Lv.4，並且等級延續至11月30日。",
        "txt6": "Ⅲ. 當前自然月結束後，VIP等級可以保留上一個月獲得的，但下個月再次從零開始累計VIP值，例如：用戶10月達到Lv.4等級，11月也保留Lv.4等級，並且在12月結束前只達到Lv.2，則從12月1日開始只享受Lv.2等級和特權。",
        "txt7": "等級",
        "txt8": "所需經驗值",
        "txt9": "上一級到此級所需經驗值"
    },
    "patricianDescription": [
        {
            "title": "1、什麼是貴族",
            "vlaue": [
                "貴族是平台上用戶尊貴身份的象徵，目前分為六個等級。",
                "不同等級的貴族有相應的特權，每月付費激活即可獲得貴族並享受特權。"
            ]
        },
        {
            "title": "2、購買規則",
            "vlaue": [
                "獲得貴族1、2、3的特權，直接支付即可激活。",
                "貴族4、5、6需要用鑽石購買激活。",
                "激活成功後，所選貴族天數將自動增加31天/月。",
                "若同時激活多個不同等級的貴族，則優先消耗較高等級的剩余天數，低等級貴族的天數保持不變。 "
            ]
        },
        {
            "title": "3、續訂規則",
            "vlaue": [
                "激活成功後，如果你在有效期內續費，你可以享受續費折扣。但你在有效期後重新激活，您將不會享受續訂折扣。",
                "激活任一等級貴族，均可獲得每日簽到鑽石獎勵，該任務需自行領取獎勵，預期領取視為自動放棄"
            ]
        }
    ],
    "patricianDescriptiontitle": "貴族說明",
    "raveParty": {
        "activityIntroduction1": " 🎉恭喜你，蕾拉为你准备了一场专属狂欢派对。💫完成充值任务和消费任务即可获得相应积分，可用于从奖池兑换物品！🎁",
        "activityIntroduction2": "此外，充值次数前10名的用户和消费次数前10的用户将获得额外的特权奖励。🏆有很多奖励，所以赶紧加入吧！💰✨",
        "benefits": "解锁专属奖励和特权",
        "consumedEvery": "每消费16000颗钻石的幸运礼物将获得20分。",
        "consumptionAmounts": "消耗金额",
        "consumptionList": "消耗榜",
        "customizedAvatarFrame3": "customized avatar frame <br/>(3 days)",
        "customizedGift3": "customized gift <br/> (3 days)",
        "customizedLaunch15": "customized launch <br/> (15 days) ",
        "customizedLaunch3": "customized entrance <br/>(3 days) ",
        "customizedLaunch7": "customized banner <br/> (7 days) ",
        "details": "详情",
        "diamonds": "钻石",
        "duringTheEvent": "根据活动期间消耗的幸运礼物钻石数量进行排名",
        "exchange": "兑换",
        "leaderboardReward": "排行榜奖励",
        "leaderboardReward1": "1.活动期间，用户充值将计入活动排行榜。充值排行榜和消费排行榜前10名用户将获得额外的特权奖励。请注意，礼品奖励的有效期为7天。请在有效期内使用。",
        "leaderboardReward2": "2.请注意：每充值2100颗钻石可获得20分",
        "leaderboardReward3": "3.所有奖励将在活动结束后3~5个工作日内送达您的账户。",
        "leaderboardReward4": "4.对于两个排行榜的前十名，请添加我们的联系方式领取专属奖励",
        "luckyGiftsRules": "幸运礼物规则",
        "luckyGiftsRules1": "1.只有幸运礼物才会被列入消耗榜",
        "luckyGiftsRules2": "2.送上幸运礼物，有机会赢取大奖。",
        "luckyGiftsRules3": "3.成组送出的幸运礼物越多，赢得头奖的机会就越大。",
        "luckyGiftsRules4": "4.奖品是钻石，会被送到你的包里。",
        "luckyGiftsRules5": "5.奖励的倍数是礼物单价的倍数。",
        "luckyGiftsRules6": "6.主播在收到幸运礼物时，只会收到礼物价值的10%。",
        "luckyGiftsRules7": "7.右上角有四叶草标志的礼物是幸运礼物",
        "luckyGiftsRulesNote": "笔记：",
        "luckyGiftsRulesNote1": "1.用户在幸运礼物活动中获得的奖励只能在Layla平台上使用，不得用于任何营利活动。",
        "luckyGiftsRulesNote2": "2.Layla保留最终解释权",
        "luckyGiftsRulesTitle": "规则：",
        "noRanking": "暂无榜单数据",
        "ok": "确定",
        "points": "积分",
        "points1": ")积分兑换",
        "pointsReward": "通过充值获得积分以兑换独家奖励！在下面的兑换区查看奖励！在活动期间，如果累计充值达到相应的钻石数量，将获得积分。",
        "pointsReward2": "请注意奖励的有效期。奖励的有效期从赎回的那一刻开始计算。",
        "pointsReward3": "请在5月6日23:59 UTC+8之前兑换。否则，积分将过期。",
        "pointsRewardTitle": "积分奖励",
        "privilege": "特权",
        "rank": "排行",
        "rankingBased": "根据活动期间充值的钻石数量进行排名",
        "recharge": "充值",
        "rechargeList": "充值榜",
        "redeemFailed": "兑换失败",
        "redeemSuccessful": "兑换成功",
        "remainingPoints": "剩余积分",
        "rewards": "奖励",
        "rules": "规则",
        "telegram": "Telegram:",
        "telegramNum": "+852 6040 9424",
        "title": "春季狂欢",
        "top1": "top1",
        "top2": "top2",
        "top3": "top3",
        "top46": "top4-6",
        "top710": "top7-10",
        "totalConsumption": "共消耗(",
        "tryAgain": "兑换积分不足,请确认后重试",
        "WhatsApp": "Our WhatsApp number： ",
        "WhatsAppNumber": "+86 184 3591 9081"
    },
    "transfer": {
        "contact_content1": "充值",
        "contact_content10": "轉賬記錄",
        "contact_content11": "輸入數量",
        "contact_content12": "我的鑽石",
        "contact_content13": "轉賬失敗，你的鑽石餘額不足",
        "contact_content14": "設置轉賬密碼",
        "contact_content15": "轉賬密碼",
        "contact_content16": "請輸入密碼",
        "contact_content17": "為防止影響你的轉賬操作，請牢記該密碼",
        "contact_content18": "確認密碼和上一步輸入密碼不一致，請重試",
        "contact_content19": "確認轉賬密碼",
        "contact_content2": "充值聯繫人",
        "contact_content20": "確認",
        "contact_content21": "你的轉賬功能被凍結，請聯繫管理員溝通",
        "contact_content22": "轉賬確認",
        "contact_content23": "轉賬人",
        "contact_content24": "轉賬數量",
        "contact_content25": "確認轉賬",
        "contact_content26": "忘記密碼？聯繫管理員重置",
        "contact_content27": "密碼錯誤，請重新嘗試",
        "contact_content28": "轉賬成功",
        "contact_content29": "轉賬通訊錄",
        "contact_content3": "複製",
        "contact_content30": "沒有轉移通訊記錄",
        "contact_content31": "沒有轉賬記錄",
        "contact_content32": "你的轉賬密碼設置成功，請牢記在心",
        "contact_content33": "轉賬",
        "contact_content34": "鑽石明細",
        "contact_content35": "鑽石餘額",
        "contact_content36": "密碼不能少於四位數",
        "contact_content4": "發起轉賬",
        "contact_content5": "輸入對方賬號",
        "contact_content6": "轉移的鑽石會立即到賬對方賬戶，無法退回；請謹慎操作",
        "contact_content7": "輸入ID",
        "contact_content8": "下一步",
        "contact_content9": "ID輸入錯誤，該用戶不存在",
        "error": "網絡連接失敗，請重試",
        "finished": "已經全部加載完畢",
        "Loading": "正在加載更多的數據..."
    },
    "eventStartsIn": "",
    "nivi_idolHelp": {
        "content1": "Nivi Idol是Nivi App給受歡迎的女孩頒發的特殊標識。成為Nivi Idol將獲得多種特權。包括特殊的標識，更多的曝光，更多的匹配消息。",
        "content2_1": "1. 首先要通過眞人認證的審核，其次，聊天分要達到80分。",
        "content2_2": "2. 當日達到活躍條件，聊天分會在次日4點(UTC+8)根據過去7日的數據計算聊天分。",
        "content2_3": "3. 當日未達到活躍條件，次日聊天分將歸0。再次活躍將重新累積計算聊天分。",
        "content2_4": "4. 當日的活躍條件為：",
        "content2_4_1": "a.過去7日在綫時長>",
        "content2_4_2": "b.過去7日主動聊天人數>",
        "content2_4_3": "c.昨日登錄過App。",
        "content3_1": "聊天分是根據你在私聊場景的受歡迎程度計算出來的。這包括：",
        "content3_2": "1. 曝光。有多少人在首頁、動態、資料頁看到過你。",
        "content3_3": "2. 在綫時長。你在App內待了多少時長。",
        "content3_4": "3. 活躍親密度數。當日有多少親密度大於3的用戶給你發送過信息。",
        "content3_5": "4. 消息被回復率。你發出消息，有多少被回復了。",
        "content3_6": "5. 主動聊天人數。你給多少个用戶打招呼或者發消息。",
        "content3_7": "6. 有效回復率。你在有效時間內回復他人消息的比率。",
        "content3_8": "7. 聊天人均耗鑽。和你聊天的人消耗了多少鑽石，包括聊天和1v1的送禮。",
        "content3_9": "8. 聊天耗鑽人數。有多少人在和你聊天時消耗過鑽石，包括聊天和1v1送禮。",
        "content4_1": "你可以在聊天分詳情頁，看到你各項數據的統計値，以及和Nivi Idol的對比情況。",
        "content4_2": "點擊對應項目的提示，會有提昇對應數値的引導。",
        "header1": "什麽是Nivi Idol",
        "header2": "如何成為Nivi Idol",
        "header3": "什麽是聊天分",
        "header4": "如何提高聊天分",
        "minute": "分鍾。",
        "people": "人。",
        "title": "Nivi Idol 幫助"
    }
}