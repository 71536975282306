export default{
    "spei_title": "دفعة SPEI",
    "spei_amount": "مبلغ الدفع",
    "spei_mxn": "MXN",
    "spei_id_type": "نوع الهوية",
    "spei_rfc": "RFC",
    "spei_curp": "CURP",
    "spei_id_number": "رقم الهوية",
    "spei_id_number_tips": "رقم الهوية غير صحيح",
    "spei_name": "الاسم",
    "spei_name_tips": "حتى 30 حرفًا، بدون أرقام أو رموز خاصة.",
    "spei_email": "البريد الإلكتروني",
    "spei_email_tips": "يرجى إدخال بريد إلكتروني صالح.",
    "spei_confirm": "تأكيد",
    "ramadan_title": "حدث رمضان",
    "ramadan_2nd_title": "تنصيف رمضان",
    "ramadan_2nd_gifttitle": "هدية الحدث",
    "ramadan_2nd_gift1": "رمضان كريم",
    "ramadan_2nd_gift2": "ليلي رمضان",
    "ramadan_2nd_gift3": "الافطار",
    "ramadan_2nd_gift4": "رمضان المحظوظ",
    "ramadan_2nd_gift_ticket": "التذاكر",
    "ramadan_2nd_tab1": "الداعمين",
    "ramadan_2nd_tab2": "المضيفات",
    "ramadan_2nd_dailyranking": "الترتيب اليومي",
    "ramadan_2nd_totalranking": "الترتيب الكلي",
    "ramadan_2nd_reward_title1": "مكافأة الداعمين",
    "ramadan_2nd_reward_title2": "مكافأة المضيفات",
    "ramadan_2nd_reward_rank": "الترتيب",
    "ramadan_2nd_reward_top1": "TOP 1",
    "ramadan_2nd_reward_top2-3": "TOP 2-3",
    "ramadan_2nd_reward_top4-10": "TOP 4-10",
    "ramadan_2nd_daily_supporter_reward": "السحب الذهبي",
    "ramadan_2nd_daily_anchor_reward": "رمضان الذهبي",
    "ramadan_2nd_total_supporter_top1": "$500",
    "ramadan_2nd_total_supporter_top2-3": "300 ألف ماسة",
    "ramadan_2nd_total_supporter_top4-10": "100 ألف ماسة",
    "ramadan_2nd_total_anchor_top1": "$300",
    "ramadan_2nd_total_anchor_top2-3": "200 ألف عملة",
    "ramadan_2nd_total_anchor_top4-10": "100 ألف عملة",
    "ramadan_3rd_title": "مجموع الشحن",
    "ramadan_3rd_pool": "مجموع الجوائز",
    "ramadan_3rd_ticket": "تصنيف التذاكر",
    "ramadan_3rd_danger": "خطر",
    "ramadan_3rd_ruletitle": "قواعد الحدث",
    "ramadan_3rd_rule1": "صندوق الجوائز: خلال الحدث، سيتم إضافة 2٪ من جميع الألماس التي يشحنها المستخدمون إلى صندوق الجوائز. (سيتم احتساب الألماس المشحون من الوكلاء، لكن لن يتم احتساب عمليات شراء وكلاء الشحن)",
    "ramadan_3rd_rule2": "التذاكر: يمكن للمستخدمين كسب التذاكر من خلال إرسال الهدايا الرمضانية المحددة.",
    "ramadan_3rd_rule3": "بعد الحدث، سيتم تقاسم صندوق الجوائز بين أفضل 10 مستخدمين حصلوا على أكبر عدد من التذاكر، وفقًا لعدد التذاكر التي جمعوها.",
    "ramadan_signin_title": "تسجيل رمضان",
    "ramadan_signin_time": "وقت الحدث",
    "ramadan_signin_accumulated": "تسجيل الدخول التراكمي",
    "ramadan_signin_current": "الحالي",
    "ramadan_signin_days": "أيام",
    "ramadan_signin_note": "ملاحظة: اشحن مرة واحدة يوميًا ويمكنك إعادة التسجيل مرة واحدة. (سيتم احتساب العملات المعدنية التي تم شراؤها من وكلاء الشحن؛ لن يتم احتساب شحن محفظة وكلاء الشحن)",
    "ramadan_signin_expired": "منتهي الصلاحية",
    "ramadan_signin_received": "تم الاستلام",
    "ramadan_signin_receivedok": "تم الاستلام بنجاح",
    "pk_vote_title": "صوّت لمضيفك المفضل!",
    "pk_vote_body": "بناءً على الأصوات، سيتم إحياء أعلى 4 مضيفين تم إقصاؤهم وإعادتهم إلى المنافسة النهائية.",
    "pk_vote_buttom": "<<< اضغط هنا للتصويت >>>",
    "pk_vote_buttom2": "تصويت",
    "pk_vote_note": "ملاحظات:",
    "pk_vote_rule1": "يمكن لكل شخص التصويت حتى 5 مرات يوميًا (UTC+8).",
    "pk_vote_rule2": "المضيفين الأربعة الذين تم إقصاؤهم والذين حصلوا على أعلى الأصوات سيحصلون على فرصة أخرى للتنافس في النهائيات.",
    "pk_vote_success": "التصويت ناجح",
    "valentine_title": "عيد حب سعيد",
    "valentine_time_startin": "يبدأ الحدث في",
    "valentine_time_ended": "انتهى الحدث",
    "valentine_time_endin": "الحدث ينتهي في:",
    "valentine_time_days": "أيام",
    "valentine_time_hours": "ساعات",
    "valentine_time_minutes": "دقائق",
    "valentine_time_seconds": "ثواني",
    "valentine_gift": "هدية الحدث",
    "valentine_gift_1": "القدر",
    "valentine_gift_2": "المحب",
    "valentine_gift_3": "منطاد الحب",
    "valentine_gift_4": "جزيرة عائمة",
    "valentine_tab1": "رحلة CP",
    "valentine_tab2": "حفظ الحب",
    "valentine_tab1_honeytrip": "رحلة عسل CP",
    "valentine_rule_title": "وصف",
    "valentine_tab1_honeytrip_rule1": "خلال الحدث، إرسال هدايا محددة بين الطرفين سيجمع نقاط الحب",
    "valentine_tab1_honeytrip_rule2": "يعرض هذا القسم بشكل افتراضي أزواج CP التي تحتوي على أعلى نقاط حب، ويمكنك التبديل بين الأزواج بالنقر على زر السحب لأسفل.",
    "valentine_tab1_honeytrip_switchcp": "تغيير CP",
    "valentine_tab1_romantictrip": "رحلة رومانسية",
    "valentine_trip_intro": "عندما تصل نقاط حب CP إلى مستوى معين، يمكنك الحصول على المكافآت المقابلة.",
    "valentine_trip_step1": "اللقاء الأول",
    "valentine_trip_step2": "يدق القلب",
    "valentine_trip_step3": "حب عميق",
    "valentine_trip_step4": "صدى الروح",
    "valentine_trip_step5": "حب ابدي",
    "valentine_trip_target": "للحصول عليها",
    "valentine_preview": "معاينة المكافأة",
    "valentine_preview_claim": "المطالبة",
    "valentine_successful": "تم الاستلام بنجاح",
    "valentine_preview_claimed": "تم الاستلام",
    "valentine_preview_unlocked": "تم الفتح",
    "valentine_tab2_rule1": "CP اليومية: يتم تحديث البيانات يوميًا في الساعة 12:00 صباحًا بتوقيت UTC+8. سيتم توزيع المكافآت يدويًا من قبل فريق العمليات بعد نهاية اليوم.",
    "valentine_tab2_rule2": "الشرف العام:قم بتجميع نقاط الحب عن طريق إرسال هدايا محددة داخل الغرفة خلال فترة الحدث، وسيتم تصنيف الغرف وفقًا لذلك.",
    "valentine_tab3": "CP اليومي",
    "valentine_tab4": "الشرف العام",
    "valentine_tab3_timecount": "الجولة المتبقية:",
    "valentine_tab3_finished": "الجولات المتبقية:",
    "valentine_rewards_button": "المكافآت",
    "valentine_rewards_romantictrip_title1": "المرحلة",
    "valentine_rewards_romantictrip_title2": "المكافآت",
    "valentine_frame1": "كوكب الزهور",
    "valentine_frame1_female": "ريشة أرجوانية",
    "valentine_chatbubble": "الحب تحت القمر",
    "valentine_entranceeffect": "الحب الذهبي",
    "valentine_frame2": "الساحرة الزرقاء\n",
    "valentine_frame2_female": "مجوهرات الورد",
    "valentine_7day": "*7 يومًا",
    "valentine_15day": "*15 يومًا",
    "valentine_20day": "*20 يومًا",
    "valentine_reward_male": "(ذكر)",
    "valentine_reward_female": "(أنثى)",
    "valentine_cpwall": "عرض بنر CP على الجدار لمدة *10 أيام",
    "valentine_cpidentification": "شهادة CP *دائم",
    "valentine_dailycp_reward": "الفائز الأول في CP اليومي سيحصل على إطار صورة مخصص، يتم توزيعه يدويًا من قبل الفريق التشغيلي يوميًا.",
    "valentine_dailycp_maleuser": "ذكر- مع اسم المستخدم",
    "valentine_dailycp_femaleuser": "أنثى- مع اسم المستخدم",
    "valentine_overall_rank": "الترتيب",
    "valentine_overall_top1": "الغرفة  TOP1",
    "valentine_overall_top2": "الغرفة TOP2",
    "valentine_overall_top3": "الغرفة TOP3",
    "valentine_overall_top4-10": "الغرفة من 4 إلى 10",
    "valentine_overall_dollar": "المكافأت",
    "valentine_overall_roombg": "غطاء خلفية غرفة رحلة الحب",
    "valentine_overall_supporter": "سيحصل أفضل 3 داعمين للغرفة الأولى على مكافآت إضافية.",
    "valentine_overall_supporter1": "داعم الغرفة الأولى",
    "valentine_overall_supporter2": "داعم الغرفة الثانية",
    "valentine_overall_supporter3": "داعم الغرفة الثالثة",
    "valentine_overall_backpackgift_artr": "هدية في حقيبة الظهر (بقيمة 50,000 ماسة)",
    "valentine_overall_backpackgift_espt": "هدية في حقيبة الظهر (بقيمة 6,000 ماسة)",
    "valentine_overall_giftname": "حقوق تسمية هدية الحدث",
    "valentine_10days": "*10 يومًا",
    "valentine_cpwall_ar": "شهر العسل CP",
    "valentine_cpwall_trespt": "",
    "valentine_reward_send": "سيتم إرساله خلال 3 أيام بعد انتهاء الحدث",
    "legend_title": "أسطوري",
    "legend_tab1": "المجموعات A",
    "legend_tab2": "المجموعات B",
    "legend_tab3": "المجموعات C",
    "legend_tab4": "الإجمالي",
    "legend_new_tab1": "الترتيب حسب الفئة",
    "legend_new_tab2": "الترتيب الإجمالي",
    "legend_tab_title_rank": "ترتيب الوكالات",
    "legend_tab_title_agencyid": "معرف الوكالة",
    "legend_tab_title_agencyname": "اسم الوكالة",
    "legend_tab_title_coins": "كوينزات",
    "legend_tab_title_rewards": "المكافآت الحالية",
    "legend_tab_title_target": "التارجيت المطلوب",
    "legend_rule_button": "قواعد الحدث",
    "legend_rule_row1": "الحدث سيبدأ من يوم3-2025",
    "legend_rule_row2": "يتم تحديد الفائزين بناءاً على التارجت النهائي الشهري في نهاية كل شهر",
    "legend_rule_row3": "يتم تقسيم الوكالات الى 3 فئات مع اجمالي مكافأت تصل الى 4080$",
    "legend_rule_row4": "الVIP المجاني يكون بناءاً على تارجت الوكالة الاجمالي و ليس له علاقة بـ الفئة الخاصة بالوكالة",
    "legend_rule_row5": "سوف يتم تحديث التصنيف للوكالات يومياً",
    "legend_rule_row6": "يتم اعلان الفائز مع اول 5 ايام من كل شهر ، الوكالة التي تحصل على المركز الاول سوف تكون الراعي الرسمي للتطبيق لمدة شهر\n",
    "legend_rule_row7": "الوكالة التي تحصل على مركز بالمسابقة لكنها لم تحقق التارجت المطلوب لهذا المركز، سوف تحصل على مردود اقل او نسبه من المردود المحدد بناءاً على التارجت الاجمالي الذي تم تحقيقه",
    "legend_rule_row8": "الوكالات الجديدة بالتطبيق لهم الحق في الانتقال الى الفئة C في منتصف الشهر اذا تم تحقيق التارجت المحدد للفئة",
    "legend_rule_row9": "9- قاعدة التحديث: بالنسبة لتغييرات الفئة، يمكن للوكالات التعامل مع بعضها البعض لمبادلة الأماكن إذا قبل كلاهما قبل تحديثات إصدار النظام تلقائيا. عندما يتم تحديث الإصدار التلقائي، لم يعد بإمكانهم تغيير الفئات. سيتم إصداره قبل السابع عشر.",
    "legend_rule_vipplan": "خطة اهداء VIP للوكالة",
    "legend_rule_vipplan_title1": "تارجت الوكالة",
    "legend_rule_vipplan_title2": "اهداء V4s",
    "legend_rule_vipplan_title3": "اهداء V5s",
    "legend_rule_vipplan_title4": "اهداء V6s",
    "legend_rule_vipplan_note": "بدأً من شهر اغسطس جميع الوكالات التي تقوم بتحقيق تارجت اعلى من 2 مليون سيكون لها الحق في استلام/اهداء ال VIP الموضحة بالسياسة الي اي شخص تحب\nمع العلم انه لا يمكن اجراء اي تغير بعد الارسال\nيتاح الاهداء فقط بعد الشهر الحالي و قبل نهاية الشهر القادم\nاذا قام المسخدم الذي حصل علي الVIP الذي يتم اهداءه من قبلنا بالشحن يمكنه الاستمرار في رفع المستوي مع العلم ان ال VIP المرسل من قبلنا لا يضيف اي قيمة على \"الشحن الشهري\" للايدي المستخدم\n\n\n\n",
    "legend_reward_button": "المكافأت",
    "legend_reward_title1": "ترتيب الوكالات",
    "legend_reward_title2": "المكافأت",
    "legend_reward_title3": "التارجيت المطلوب",
    "legend_reward_title4": "ملاحظات",
    "legend_reward_note1": "إذا لم تحقق الوكالة التارجيت المطلوب، ستحصل على أقل مكافآت.",
    "legend_reward_note2": "إذا لم تحقق الوكالة التارجت المطلوب، ستحصل على 50 بالمئة من المكافآت.",
    "legend_reward_dollar_1": "$600",
    "legend_reward_dollar_2": "$500",
    "legend_reward_dollar_3": "$400",
    "legend_reward_dollar_4": "$300",
    "legend_reward_dollar_5": "$200",
    "legend_reward_diamonds_1": "200 الف ماسة",
    "legend_reward_diamonds_2": "160 الف ماسة",
    "legend_reward_diamonds_3": "120 الف ماسة",
    "legend_reward_diamonds_4": "100 الف ماسة",
    "legend_reward_diamonds_5": "80 الف ماسة",
    "legend_reward_diamonds_6": "60 الف ماسة",
    "legend_reward_coins_1": "12 مليون كوينز",
    "legend_reward_coins_2": "10 مليون كوينز",
    "legend_reward_coins_3": "8 مليون كوينز",
    "legend_reward_coins_4": "5 مليون كوينز",
    "legend_reward_coins_5": "3 مليون كوينز",
    "legend_kdiamonds": "الف ماسة",
    "legend_mcoins": "مليون كوينز",
    "legend_categoryc_top7reward": "ستحصل أفضل 7 وكالات على مكافآت",
    "legend_freevip_new": "VIP مجاني",
    "legend_vip4s": "VIP4S",
    "legend_vip5s": "VIP5S",
    "legend_vip6s": "VIP6S",
    "legend_appsponsor": "راعي التطبيق",
    "legend_reward_link1": "ملاحظة: اضغط هنا لعرض قواعد المكافآت لتصنيفات الفئات.",
    "legend_reward_link2": "ملاحظة: اضغط هنا لعرض قواعد المكافآت للتصنيف العام.",
    "xmas_title": "سنة جديدة سعيدة",
    "xmas_gift1": "عيد ميلاد مجيد",
    "xmas_gift2": "حب النيون",
    "xmas_gift3": "شجرة عيد الميلاد",
    "xmas_gift4": "سنة جديدة سعيدة",
    "xmas_espt_host_top1": "100,000 كوينزات",
    "xmas_espt_host_top2-3": "30,000 كوينزات",
    "xmas_espt_host_top4-10": "10,000 كوينزات",
    "xmas_espt_supporter_top1": "VIP10<br/>*30 يومًا",
    "xmas_espt_supporter_top2-3": "VIP7<br/>*30 يومًا",
    "xmas_espt_supporter_top4-10": "VIP5<br/>*30 يومًا",
    "xmas_tr_host_top1": "$200",
    "xmas_tr_host_top2-3": "$50",
    "xmas_tr_host_top4-10": "$10",
    "xmas_tr_supporter_top1": "$200",
    "xmas_tr_supporter_top2-3": "$50",
    "xmas_tr_supporter_top4-10": "$10",
    "xmas_ar_host_top1": "$200",
    "xmas_ar_host_top2-3": "100,000 كوينزات",
    "xmas_ar_host_top4-10": "50,000 كوينزات",
    "xmas_ar_supporter_top1": "500 دولار",
    "xmas_ar_supporter_top2-3": "300,000 ماسة",
    "xmas_ar_supporter_top4-10": "100,000 ماسة",
    "xmas_rankingcountry_espt": "منطقة الترتيب: الإسبانية والبرتغالية",
    "xmas_rankingcountry_tr": "منطقة الترتيب: التركية",
    "xmas_rankingcountry_ar": "منطقة الترتيب: العربية",
    "roomcup_title": "كأس الغرفة",
    "roomcup_Room ID ": "معرف الغرفة",
    "roomcup_countdown": "حتى نهاية هذا الاسبوع",
    "roomcup_day": "أيام",
    "roomcup_hour": "ساعات",
    "roomcup_minutes": "دقائق",
    "roomcup_seconds": "ثواني",
    "roomcup_coin_recieve": "الهدايا المتراكمة التي تلقيتها هذا الأسبوع",
    "roomcup_target_icon": "تارجت",
    "roomcup_reward_icon": "مكافأة",
    "roomcup_roomreward": "مكافآت الغرف",
    "roomcup_roomreward_lasttarget": "التارجت وصلت في الأسبوع الماضي:",
    "roomcup_roomreward_lastreward": "مكافآت الاسبوع الماضي:",
    "roomcup_claim_icon": "استلام",
    "roomcup_claimed_icon": "تم الأستلام",
    "roomcup_doubletargetcontest": "مسابقة التارجت المضاعفة",
    "roomcup_ratio": "نسبة المكافأة الإضافية:",
    "roomcup_doubletarget": "مضاعفة التارجت:",
    "roomcup_doubletarget_reward": "مكافآت مضاعفة التارجت:",
    "roomcup_noroom": "لم تقم بإنشاء غرفة بعد",
    "roomcup_noarea": "هذه المنطقة غير مشاركة في الحدث",
    "roomcup_rule_icon": "القاعدة",
    "roomcup_rule_row1": "يتم تحديث بيانات الحدث UTC+8 كل يوم اثنين؛",
    "roomcup_rule_row2": "مكافأة الغرفة: يمكنك الحصول على مكافآت مقابل أعلى مستوى تم تحقيقه في الأسبوع السابق؛",
    "roomcup_rule_row3": "مكافآت مضاعفة التارجت: إذا ضاعفت الهدف الذي تم تحقيقه في الأسبوع السابق خلال الأسبوع الحالي، فستحصل على مكافآت إضافية؛",
    "roomcup_rule_row4": "إذا لم تحصل على المكافآت لهذا الأسبوع، فسيتم اعتبار ذلك استسلامًا ولا يمكن استبداله.",
    "roomcup_coins": "كوينزات",
    "roomcup_diamonds": "الماس",
    "roomcup_notachieved": "لم يتحقق أي",
    "roomcup_successful": "تم الاستلام بنجاح",
    "budang_title": "ليلى الحدث-تارجت توسيع",
    "budang_beforestart": "يبدأ الحدث في",
    "budang_inprogress": "ينتهي الحدث في",
    "budang_ended": "انتهى الحدث",
    "budang_days": "أيام",
    "budang_hours": "ساعات",
    "budang_minutes ": "دقائق",
    "budang_seconds": "ثواني",
    "budang_open": "فتح",
    "budang_open_cannot": "لم تحصل على صندوق الكنز بعد ولا تستطيع فتحه.",
    "budang_open_once": "لا يمكن فتحه إلا مرة واحدة يوميًا أثناء الحدث",
    "budang_open_ended": "انتهى الحدث",
    "budang_myagency": "وكالتي:",
    "budang_mytarget": "تارجتي:",
    "budang_distanceaway": "لا يزال",
    "budang_distanceaway_2": "بعيدًا عن",
    "budang_highestlevel": "لقد وصلت إلى أعلى مستوى",
    "budang_rewardname": "صندوق الكنز",
    "budang_reward1": "إطار الصورة الرمزية * 1 يوم",
    "budang_reward2": "دخول * 1 يوم",
    "budang_reward3": "",
    "budang_reward_rule": "احصل على إحدى المكافآت المذكورة أعلاه عشوائيًا",
    "budang_rule_button": "قاعدة",
    "budang_rule_row1": "UTC+3 12.15 19:00 - 12.31 19:00",
    "budang_rule_row1_202501": "UTC+3 1.15 19:00 - 1.31 19:00",
    "budang_rule_row1_202502": "UTC+3 3.15 19:00 - 3.31 19:00",
    "budang_rule_row2": "تارجتي: إجمالي الكوينزات التي تم الحصول عليها خلال الحدث. (يتم حساب جميع الكوينزات بما في ذلك \"الأحداث\" و\"المهام\" في التارجت)",
    "budang_rule_row3": "تحتوي المستويات المختلفة لصناديق الكنز على مجموعة متنوعة من المكافآت المختلفة، ويمكنك الحصول على واحدة عشوائيًا في كل مرة تفتح فيها صندوق الكنز. لا يمكنك فتح سوى صندوق كنز واحد كل يوم بغض النظر عن المستوى.",
    "budang_rule_row4": "كل مكافأة يتم استلامها صالحة لمدة يوم واحد.",
    "resellerNotTransaction": "هذا المستخدم خارج نطاق التداول الخاص بك، وبالتالي لا يمكن إتمام العملية.",
    "tingzhan_title_tr": "",
    "tingzhan_title": "حفل المجد",
    "tingzhan_time_title": "وقت الحدث",
    "tingzhan_time": "UTC+8 03.24 00:00 - 04.06 24:00",
    "tingzhan_rule_buttom": "القواعد والمكافآت",
    "tingzhan_tab1": "جولة تأهيلية ",
    "tingzhan_tab1_time": "03.24-03.25",
    "tingzhan_tab1_rule_row1": "أفضل 16",
    "tingzhan_tab1_rule_row2": "التصنيف يعتمد على العدد التراكمي الكوينزات المرسلة من هدايا الحدث في الغرفة، عدد الكوينزات هو نفسه، الغرفة التي تصل إلى هذا الرقم أولاً سيتم تصنيفها أعلى.",
    "tingzhan_tab1_rule_row3": "في هذه الجولة، تتقدم الغرف الأفضل من 1 إلى 8 إلى المجموعة الذهبية، وتدخل الغرف الأفضل من 9 إلى 16 إلى المجموعة الفضية.",
    "tingzhan_tab1_rule_row4": "UTC+8 03.24 00:00 - 03.25 24:00",
    "tingzhan_tab_gold": "المجموعة الذهبية",
    "tingzhan_tab_silver": "المجموعة الفضية",
    "tingzhan_tab2": "جولة متقدمة ",
    "tingzhan_tab2_time": "03.27-03.29",
    "tingzhan_tab2_goldrule_row1": "أفضل 6",
    "tingzhan_tab2_goldrule_row2": "التصنيف يعتمد على العدد التراكمي الكوينزات المرسلة من هدايا الحدث في الغرفة، عدد الكوينزات هو نفسه، الغرفة التي تصل إلى هذا الرقم أولاً سيتم تصنيفها أعلى. الغرفتان اللتان تحتلان المركزين السابع والثامن ستقعان في المجموعة الفضية.",
    "tingzhan_tab1_goldrule_row3": "UTC+8 03.27 00:00 - 03.29 24:00",
    "tingzhan_tab2_silverrule_row1": "أفضل 4",
    "tingzhan_tab2_silverrule_row2": "التصنيف يعتمد على العدد التراكمي الكوينزات المرسلة من هدايا الحدث في الغرفة، عدد الكوينزات هو نفسه، الغرفة التي تصل إلى هذا الرقم أولاً سيتم تصنيفها أعلى. سيتم إزالة الغرف المصنفة من 5 إلى 8.",
    "tingzhan_tab2_silverrule_row3": "UTC+8 03.27 00:00 - 03.29 24:00",
    "tingzhan_tab3": "جولة التحدي ",
    "tingzhan_tab3_time": "03.31-04.02",
    "tingzhan_tab3_goldrule_row1": "أفضل 3",
    "tingzhan_tab3_goldrule_row2": "تحدي 1 غرفة على 1 غرفة مع الكوينزات المتراكمة لمدة 3 أيام. الفائز يتقدم، والخاسر يتقدم إلى المجموعة الفضية.",
    "tingzhan_tab3_goldrule_row3": "هناك حصة للقيامة في هذه الجولة، والخاسر الذي لديه أكبر عدد من الكوينزات سيتم إحياؤه.",
    "tingzhan_tab3_goldrule_row4": "UTC+8 03.31 00:00 - 04.02 24:00",
    "tingzhan_tab3_silverrule_row1": "أفضل 3",
    "tingzhan_tab3_silverrule_row2": "تحدي 1 غرفة على 1 غرفة مع الكوينزات المتراكمة لمدة 3 أيام. سيتم القضاء على الخاسر، عدد الكوينزات هو نفسه، الغرفة التي تصل إلى هذا الرقم أولاً سيتم تصنيفها أعلى.",
    "tingzhan_tab3_silverrule_row3": "UTC+8 03.31 00:00 - 04.02 24:00",
    "tingzhan_tab4": "النهائي ",
    "tingzhan_tab4_time": "04.04-04.06",
    "tingzhan_tab4_rule_row1": "النهائي",
    "tingzhan_tab4_rule_row2": "التصنيف يعتمد على العدد التراكمي الكوينزات المرسلة من هدايا الحدث في الغرفة، عدد الكوينزات هو نفسه، الغرفة التي تصل إلى هذا الرقم أولاً سيتم تصنيفها أعلى.",
    "tingzhan_tab4_rule_row3": "UTC+8 04.04 00:00 - 04.06 24:00",
    "tingzhan_tab4_countdown": "العد التنازلي النهائي",
    "tingzhan_danger": "خطر",
    "tingzhan_win": "فوز",
    "tingzhan_out": "خروج",
    "tingzhan_Revive": "إحياء",
    "tingzhan_Silver": "فضة",
    "tingzhan_rule_back": "عودة",
    "tingzhan_rule_tab1": "مقدمة الحدث",
    "tingzhan_rule_tab2": "مكافآت",
    "tingzhan_rule_tab1_title": "هدايا الحدث",
    "tingzhan_rule_luvkygift1": "?",
    "tingzhan_rule_gift2": "قطة صخرية",
    "tingzhan_rule_gift3": "الصقار",
    "tingzhan_rule_gift4": "خريطة الكنز",
    "tingzhan_rule_gift4_name": "االرعاية (أعلى 1) * 10 أيام",
    "tingzhan_gift_price": "السعر",
    "tingzhan_tab2_banner_title": "1.5 مرة نقاط الخبرة!",
    "tingzhan_tab2_banner_body": "عند إرسال هدايا الحدث أثناء الحدث، ستحصل على 1.5 مرة نقاط الخبرة.",
    "tingzhan_tab2_roomreward": "مكافآت الغرف",
    "tingzhan_tab2_supporterreward": "مكافآت الداعمين",
    "tingzhan_tab2_reward": "المكافآت",
    "tingzhan_tab2_roombg": "خلفية المتحركة",
    "tingzhan_roomcover": "غطاء الغرفة",
    "tingzhan_1stplace": "أعلى 1",
    "tingzhan_2ndplace": "أعلى 2",
    "tingzhan_3rdplace": "أعلى 3",
    "tingzhan_tab2_usd": " دولار",
    "tingzhan_500usd": "500 دولار",
    "tingzhan_400usd": "400 دولار",
    "tingzhan_300usd": "300 دولار",
    "tingzhan_200usd": "200 دولار",
    "tingzhan_100usd": "100 دولار",
    "tingzhan_tab2_avatar": "إطار الصورة الرمزية",
    "tingzhan_tab2_entrance": "تأثير المدخل",
    "tingzhan_tab2_specialid": "معرف خاص",
    "tingzhan_tab2_sponsorship": "رعاية الهدايا للحدث",
    "tingzhan_supporter_1stplace": "داعمو الغرفة الفائزة بالمركز الأول",
    "tingzhan_supporter_2ndplace": "داعمو الغرفة الفائزة بالمركز الثاني",
    "tingzhan_supporter_3rdplace": "داعمو الغرفة الفائزة بالمركز الثالث",
    "tingzhan_30days": "30 يومًا",
    "tingzhan_15days": "15 يومًا",
    "tingzhan_7days": "7 أيام",
    "tingzhan_3days": "3 أيام",
    "tingzhan_tab2_specialid_body1": "عشوائي 2/3/4 - معرف رقمي",
    "tingzhan_tab2_specialid_body2": "الرقم الخاص: 50/500/5000",
    "tingzhan_tab2_sponsorship_body": "االرعاية (أعلى 1) * 10 أيام",
    "tingzhan_tab2_other": "بالإضافة إلى نقاط الخبرة، سيتم إرسال مكافآت الحدث خلال 3 أيام عمل إلى حقيبة ظهر المستخدم.",
    "tingzhan_notstart": "الحدث لم يبدأ بعد",
    "turkeyCumhuriyet": "Cumhuriyet Bayramı حدث",
    "turkeyDistance": "يبدأ الحدث في",
    "turkeyStart": "ينتهي الحدث في",
    "turkeyEnd": "انتهى الحدث",
    "turkeyEventGifts": "هدايا الحدث",
    "turkeyCumhuriyetGift": "Cumhuriyet Bayramı",
    "turkey80": "80 ألف عملة",
    "turkeyDays": "أيام",
    "turkeyHours": "ساعات",
    "turkeyMin": "دقيقة",
    "turkeyToStart": "للبدء",
    "turkeyTurkey": "تركيا",
    "turkeyTop1": "أعلى 1",
    "turkey500": " 500 دولار",
    "turkeyTop2_3": "أعلى 2-3 ",
    "turkey300": "300 ألف ماسة",
    "turkeyTop4_10": "أعلى 4-10 ",
    "turkey100": "100 ألف ماسة",
    "turkey200": " 200 دولار",
    "turkey200k": "200 ألف عملة",
    "turkeyTop4_10_2": "أعلى 20 غرفة",
    "turkeySupporters": "المؤيدون",
    "turkeyAnchors": "المضيفون",
    "turkeyParties": "الحفلات",
    "turkeyRanking": "تصنيف الدول: تركيا",
    "turkeyRanking2": "تصنيف الدول: ليست تركيا",
    "turkeytop1newanchor": "200 ألف عملة",
    "turkeytop2-3newanchor": "50 ألف عملة",
    "turkeytop4-10newanchor": "20 ألف عملة",
    "turkeytop1newsupport": "نوبل 6* 30 يومًا",
    "turkeytop2-3newsupport": "نوبل 5* 30 يومًا",
    "turkeytop4-10newsupport": "نوبل 4* 30 يومًا",
    "wealthNeedNextLevel": "يمكن ترقية الخبرة إلى المستوى التالي",
    "pkTouramentPro": "بطولة التحديات برو",
    "pkTop1": "أعلى 1",
    "pkTop2": "أعلى 2",
    "pkTop3": "أعلى 3",
    "pk1000Usd": "1000 دولار",
    "pk500Usd": "500 دولار",
    "pk300Usd": "300 دولار",
    "pkRewards": "مكافآت لكل جولة تأهيلية",
    "pkRewards2": "المكافآت لكل جولة متقدمة",
    "pk50kCoins": "50,000 كنز المحسوبة في التارجت",
    "pk100kCoins": "100,000 كنز المحسوبة في التارجت",
    "pkNote": "ملاحظة: بالنسبة لمكافآت الجولة التأهيلية وجولة المتقدمة، إذا كانت الماسات التي تم إنفاقها في PK المحددة أقل من مبلغ المكافأة، فلن يتم إرسال المكافأة ولكن سيتم احتساب نتيجة PK",
    "pk200Usd": "200 دولار",
    "pk30Day": "إطار * 30 يومًا",
    "pk15Day": "إطار * 15 يومًا",
    "pk7Day": "إطار * 7 يومًا",
    "pkNote2": "ملاحظة: يتم احتساب الدعم في جميع جولات PK. يتم احتساب الهدايا المحظوظة بنسبة 10% ويتم احتساب صناديق الكنز بناءً على مبلغ الهدية التي تم الفوز بها.",
    "pkTourament": "الطولة",
    "pkToday": "جدول اليوم",
    "pkSupporter": "داعم",
    "pkQualifying": "جولة تأهيلية",
    "pkQualifying1": "جولة تأهيلية 1",
    "pkQualifying2": "جولة تأهيلية 2",
    "pkPromotion": "جولة متقدمة",
    "pkSummit": "مباريات القمة",
    "pk32Finals": "1/32-النهائيات",
    "pk16Finals": "1/16-النهائيات",
    "pk8Finals": "1/8-النهائيات",
    "pkQuater": "ربع النهائي",
    "pkSemi": "نصف النهائي",
    "pkFinal": "النهائي",
    "pk3rd": "المباراة للمركز الثالث",
    "pk_host_reward_top1": "",
    "pk_host_reward_top1_b": "",
    "pk_host_reward_top2": "",
    "pk_host_reward_top2_b": "",
    "pk_host_reward_top3": "",
    "pk_host_reward_top3_b": "",
    "pk_supporter_reward_top1": "",
    "pk_supporter_reward_top1_b": "",
    "pk_supporter_reward_top2": "",
    "pk_supporter_reward_top2_b": "",
    "pk_supporter_reward_top3": "",
    "pk_supporter_reward_top3_b": "",
    "pk_host_rule_row1": "",
    "pk_host_rule_row2": "",
    "pk_host_rule_note": "",
    "pk_supporter_rule_note": "",
    "pk_es_rule1": "",
    "pk_es_rule2": "",
    "pk_es_rule3": "",
    "pk_es_host_top1": "",
    "pk_es_host_top2": "",
    "pk_es_host_top3": "",
    "pk_es_supporter_top1": "",
    "pk_es_supporter_top2": "",
    "pk_es_supporter_top3": "",
    "pk_es_15day": "",
    "pk_es_10day": "",
    "pk_es_5day": "",
    "dreamMidsummer": "حلم ليلة منتصف الصيف",
    "dreamSend": "أرسل هدايا حصرية لتختم حبك في منتصف الصيف",
    "dreamRank": "إرسال هدايا الحدث التالية خلال الحدث. كلما ارتفع التصنيف، زادت الجوائز. تعال وشارك للحصول على مكافآت سخية!",
    "dreamPreview": "معاينة المكافآت",
    "dreamSupporter": "داعم",
    "dreamHost": "مضيف",
    "dreamTop1": "أعلى 1",
    "dreamTop2": "أعلى 2",
    "dreamTop3": "أعلى 3",
    "dreamTop4_10": "أعلى 4-10",
    "dreamRankings": "ترتيب",
    "dreamRose": "عرش الورد",
    "dreamRoseBasket": "سلة الورد",
    "dreamButterfly": "بلاد العجائب",
    "dreamLovers": "الحب الأبدي",
    "dreamFlower": "كم الحب",
    "dream15d": "15* يوم",
    "dreamCentury": "القرن المجيد",
    "dream7": "7*",
    "dream7d": "7* أيام",
    "dreamHeart": "رحلة القلب",
    "dreamSummerLove": "حب الصيف",
    "dreamFancy": "الهوى يونيكورن",
    "dreamRules": "قواعد",
    "dreamTime": "وقت الحدث",
    "dreamPlay": "كيف تلعب",
    "dreamUsersTop": "خلال الحدث، ومن خلال إرسال هدايا محددة، يتم تصنيف المستخدمين بناءً على عدد الماسات المقدمة والكنز المستلمة.يمكن للمستخدمين الحاصلين على أعلى التصنيفات الحصول على مكافآت رائعة.",
    "dreamRewards": "مكافآت لترتيب سلة الورد وبلاد العجائب",
    "dreamFrame": "إطار عرش الورد ",
    "dreamCenturyGift": "هدية القرن المجيد",
    "dreamEntrance": "مدخل رحلة القلب",
    "dream5": "5*",
    "dream5d": "5* أيام",
    "dream3": "3*",
    "dream3d": "3* أيام",
    "dreamDiamonds": "الماسات",
    "dream25000": "25000*",
    "dreamVerification": "شارة الحب الصيفي",
    "dreamUnicorn": "الهوى يونيكورن",
    "dreamEverlasting": "مكافآت لترتيب الحب الأبدي وكم الحب",
    "dreamCoins": "الكنز",
    "dream100000": "100000*",
    "dream50000": "50000*",
    "dream5000": "5000*",
    "dreamHostBottom": "الكنز المحسوبة في التارجت",
    "zainCashTitle": "زين كاش",
    "zainTotalPay": "إجمالي الدفع",
    "zainIqd": "IQD",
    "zainName": "الاسم",
    "zainNameTips": "أدخل اسمك كما هو في بطاقة الهوية الوطنية",
    "zainPhoneNumber": "رقم الهاتف",
    "zainPhoneNumberTips": "أدخل رقم هاتفك",
    "zainSubmit": "دفع",
    "zainNumberErrorTips": "الرجاء إدخال رقم أو علامة +",
    "zainNameNotNull": "لا يمكن ترك الاسم فارغ",
    "luckyEventStartsIn": "ميعاد بدء الحدث ",
    "luckyEventEndsIn": "ينتهي الحدث في ",
    "luckyEventInstruction": "جوائز الحدث",
    "luckySendingFollowing": "الهدايه التالية فقط يتم احتسابها في الحدث(هديه الحظ .١٪؜",
    "luckyRoom": "الغرف المميزه",
    "luckyRoomTips": "اي غرفه سوف تصل الي ٥٠٠ الف او اكثر سوف يحصل علي  خلفيه كاس الروم",
    "luckyGifter": "الداعمين",
    "luckyAnchors": "المضيفين",
    "luckyGoldenBoot": "الحذاء الذهبي",
    "luckyFootball": "كرة القدم",
    "luckyDefensiveMaster": "ماجستير في الدفاع",
    "luckyTheWorldCup": "كأس العالم",
    "luckyNoOne": "لا احد في القائمة حتي الان",
    "luckyRules": "تفاصيل الجوائز",
    "luckyHowTo": "تعليمات",
    "luckyHowToGetI": "كيف تحصل عليها",
    "luckyTheGifts": "حدث ليلي لكرة القدم يبداء يوم ٢٦/٧ الساعه ٧ مساء و ينتهي يوم ١٠/٨ الساعه ٧ مساء بتوقيت مصر. \nيتم احتساب الاربع هدايه الجديده فقط في الحدث .\nاي غرفه المميزه سوف تصل الي ٥٠٠ الف او اكثر سوف تحصل علي خلفيه كاس روم المميزه التي يمكنك ان تجدها في صفحه الامتيازات الخاصه بك.\nو ايضا المذيعين و الداعيمن الاعلي دعم و الاعلي استقبال لهدايه الحدث سوف يستفادوا بمبالغ ماديه ، ماسات ، و ايضا تارجتات للمذيعين كالاتي \n",
    "luckyRewards": "خلفيه كأس كرة القدم للغرفه",
    "luckyRoomReward": "اعلي داعمين ",
    "luckyGifterRewards": "اعلي مذيعين",
    "luckyBlessingCard": "جوائز الحدث",
    "luckyTop_1": "٣٠٠ دولار",
    "luckyTop_2": "\n٢٠٠ الف ماسه",
    "luckyTop_3": "١٠٠ الف ماسه",
    "luckyTop_1_1": "تارجت ٢٠٠ الف",
    "luckyTop_2_1": "تارجت ١٠٠ الف",
    "luckyTop_3_1": "تارجت ٥٠ الف",
    "luckyTop_4": "لافتة حصرية",
    "luckyEventEnd": "انتهي الحدث ",
    "anchorActive": {
        "activeList": "قائمة النشطين",
        "activeListRewards": "مكافآت قائمة النشطين:",
        "back": "رجوع",
        "banner10": "لافتة مخصصة (10 أيام)",
        "capture": "جذب الانتباه؛ اربح المكافآت",
        "charming": "مكافآت قائمة الجاذبية:",
        "charmingList": "قائمة الجاذبية",
        "days": "أيام",
        "entrance10": "دخول حصري (10 أيام)",
        "entrance5": "دخول حصري (5 أيام)",
        "entrance7": "دخول حصري (7 أيام)",
        "frame10": "إطار صورة رمزية حصري (10 أيام)",
        "frame5": "إطار صورة رمزية حصري (5 أيام)",
        "frame7": "إطار صورة رمزية حصري (7 أيام)",
        "hours": "ساعات",
        "laseWeek": "الأسبوع الماضي",
        "laseWeekRank": "الأسبوع الماضي",
        "listOne": "قائمة: قائمة النشطين",
        "listOneMsg1": "الإيرادات النقاط التي يكسبها المضيف من خلال 1v1 = قيمة النشاط المقابلة (بما في ذلك ردود المضيف، والمكالمات الصوتية والمرئية، وجميع النقاط باستثناء تلقي الهدايا)",
        "listOneMsg2": "语音房上麦时长/分钟=10积分",
        "listTwo": "الترتيب 2: قائمة الجاذبية",
        "listTwoMsg1": "كمية هدايا الألماس في 1v1 = قيمة الجاذبية المقابلة",
        "listTwoMsg2": "ألماس هدايا غرفة الصوت = ضعف قيمة الجاذبية",
        "listTwoMsg3": "الهدايا المحظوظة تُحسب بنسبة 10% من قيمة الجاذبية",
        "minutes": "دقائق",
        "noRanking": "لا توجد بيانات تصنيف متاحة",
        "points100000": "10 آلاف نقطة",
        "points30k": "30 ألف نقطة",
        "points50000": "5 آلاف نقطة",
        "points50k": "50 ألف نقطة",
        "rank": "التصنيف",
        "rewards": "المكافآت",
        "rules": "القواعد",
        "seconds": "ثواني",
        "top1": "TOP1",
        "top2": "TOP2",
        "top3": "TOP3"
    },
    "bindAccount": {
        "Bank_Transfer": "التحويل البنكي",
        "Bind_Account": "ربط الحساب",
        "limit": {
            "account_name_tips": "أدخل الاسم الكامل بالإنجليزية",
            "bank_card_number": "أدخل رقم الحساب البنكي، مثل: 10000000000",
            "bankCode": "أدخل رمز السويفت البنكي، مثل: CMBCCNBS",
            "bankName": "أدخل اسم البنك بالإنجليزية، مثل: State Bank of India",
            "City": "أدخل مدينتك بالإنجليزية",
            "country_code": "أدخل رمز البلد بأحرف كبيرة، مثل: IN",
            "name_tips": "أدخل الاسم الأول بالإنجليزية",
            "payoneer_email": "ابحث عن البريد الإلكتروني المرتبط بحسابك في Payoneer",
            "payoneerID": "ابحث عن معرف Payoneer الخاص بك في حسابك على Payoneer، مثل: 47289645466",
            "paypal_email": "أدخل البريد الإلكتروني المرتبط بحسابك في PayPal",
            "State_or_Province": "أدخل الولاية أو المقاطعة بالإنجليزية",
            "Street": "أدخل الشارع بالإنجليزية",
            "StrePostal_Codeet": "أدخل الرمز البريدي للعنوان"
        },
        "Payment_Method": "طريقة الدفع",
        "placeholder": {
            "bank_account_name": "اسم حساب المستلم البنكي",
            "bank_card_number": "رقم حساب المستلم البنكي",
            "bankCode": "رمز بنك المستلم",
            "bankName": "اسم بنك المستلم بالإنجليزية",
            "Beneficiary_country_code": "رمز دولة المستلم",
            "City": "عنوان المستلم - المدينة",
            "country": "رمز دولة بطاقة البنك",
            "Payee_First_name": "الاسم الأول للمستلم",
            "Payee_Last_Name": "اسم عائلة المستلم",
            "payoneer_email": "أدخل بريدك الإلكتروني",
            "payoneerID": "أدخل معرف Payoneer الخاص بك",
            "paypal_email": "أدخل بريدك الإلكتروني للحساب",
            "Postal_Code": "عنوان المستلم - الرمز البريدي",
            "State_or_Province": "عنوان المستلم - الولاية أو المقاطعة",
            "Street": "شارع"
        },
        "popup": {
            "Bound_account": "ربط الحساب:",
            "cancel": "إلغاء",
            "complete": "تم",
            "confirm": "تأكيد",
            "country": "بلد بطاقة البنك",
            "payment_method": "طريقة الدفع",
            "payoneer_email": "بريد حساب Payoneer الإلكتروني"
        },
        "title": {
            "bank_account_name": "اسم حساب المستلم البنكي",
            "bank_card_number": "رقم حساب المستلم البنكي",
            "bankCode": "رمز بلد بطاقة البنك",
            "bankName": "اسم البنك المستلم",
            "country": "بلد بطاقة البنك",
            "Payee_Address": "عنوان المستلم",
            "Payee_First_name": "الاسم الأول للمستلم",
            "Payee_Last_Name": "اسم العائلة للمستلم",
            "payoneer_email": "البريد الإلكتروني لحساب البايونير",
            "payoneerID": "ايدي البايونير",
            "paypal_email": "البريد الإلكتروني لحساب PayPal"
        },
        "toast": {
            "bank_account_name": "يجب ألا يكون اسم الحساب المصرفي للمستلم فارغًا",
            "bank_card_number": "يجب ألا يكون رقم الحساب المصرفي للمستلم فارغًا",
            "bankCode": "يجب ألا يكون رمز البنك المستلم فارغًا",
            "bankName": "يجب ألا يكون اسم البنك المستلم فارغًا",
            "Beneficiary_country_code": "يجب ألا تكون دولة المستلم فارغة",
            "City": "يجب ألا تكون مدينة المستلم فارغة",
            "country": "يجب ألا تكون دولة البطاقة المصرفية فارغة",
            "emailyz": "الرجاء إدخال عنوان بريد إلكتروني صالح وقانوني",
            "Payee_First_name": "يجب ألا يكون الاسم الأول للمستلم فارغًا",
            "Payee_Last_Name": "يجب ألا يكون اسم العائلة للمستلم فارغًا",
            "payoneer_account": "يرجى التحقق مرتين لتجنب أي خسارة مالية",
            "payoneer_email": "يجب ألا يكون بريد Payoneer الإلكتروني فارغًا",
            "payoneerID": "يجب ألا يكون معرف Payoneer فارغًا",
            "paypal_email": "يجب ألا يكون بريد Payoneer الإلكتروني فارغًا",
            "State_or_Province": "يجب ألا تكون الولاية أو المقاطعة للمستلم فارغة",
            "Street": "يجب ألا يكون عنوان الشارع للمستلم فارغًا"
        }
    },
    "diamondDescription": {
        "title": "قواعد VIP و SVIP",
        "txt1": "1. يمكن تفعيل العضوية VIP وSVIP معًا.",
        "txt2": "2. يمكن لأعضاء VIP الحصول على قسائم الرسائل المجانية يوميًا.",
        "txt3": "3. يمكن لأعضاء SVIP الحصول على الماس المجاني يوميًا.",
        "txt4": "4. يمكن لأعضاء SVIP إكمال مهام استرداد الألماس يوميًا.",
        "txt5": "5. مهام استرداد الألماس لأعضاء SVIP يجب مطالبتها في نفس اليوم الذي تم تحديثها. قسائم الرسائل اليومية لأعضاء VIP يجب أيضًا مطالبتها في نفس اليوم. لا تتراكم إذا لم يتم مطالبتها وتنتهي صلاحيتها.",
        "txt6": "6. إن الإنفاق في اللعبة لن يتم احتسابه ضمن كمية الماس المستهلكة."
    },
    "eventRewards": {
        "player": "عدد المستخدمين النشطين يوميًا",
        "proportion": "نسبة توزيع المكافآت",
        "title": "عودة النشاط",
        "txt1": "1. سيتم حساب البيانات بعد انتهاء اليوم، حيث ينتهي اليوم في تمام الساعة 00:00 (UTC+8) بتوقيت الصين.",
        "txt2": "2. سيتم توزيع المكافآت بناءً على عدد اللاعبين النشطين والاستهلاك الفعلي للماس (عدد المستخدمين النشطين-الماس الذي خسارته من قبل المستخدمين).",
        "txt3": "3. يتم حساب البيانات الخاصة بالأسبوع السابق كل يوم إثنين عند الساعة 12 منتصف الليل بتوقيت (UTC+8)، وسيتم توزيع الجوائز في غضون ثلاثة أيام.",
        "txt4": "4. نسبة توزيع المكافآت:",
        "txt5": "5. تعريف اللاعب النشط: الشخص الذي يقوم بإجمالي مراهنات يتجاوز 1000 ماسة خلال اليوم بغرفة المضيف."
    },
    "failed": "فشلت العملية!",
    "idolHelp": {
        "content1": "Layla Idol هو تصنيف خاص يمنحه تطبيق Layla للفتيات المشهورات. أن تصبحي Layla Idol يأتي مع امتيازات متعددة، بما في ذلك شارة خاصة، تعرض أكثر، ورسائل مطابقة أكثر.",
        "content2_1": "1. أولاً، يجب أن تجتازي التحقق من الشخص الحقيقي. ثانياً، يجب أن يصل معدل الدردشة الخاص بك إلى ٨٠ نقطة",
        "content2_2": "2. استوفي شروط النشاط في اليوم، وسيتم حساب معدل الدردشة في الساعة ٤ صباحًا (UTC+8) في اليوم التالي استنادًا إلى بيانات الأيام ال٧ الماضية",
        "content2_3": "3. إذا لم تتم مقابلة شروط النشاط في اليوم، سيعاد تعيين معدل الدردشة إلى ٠ في اليوم التالي. العودة للنشاط مرة أخرى ستبدأ تراكم وحساب معدل الدردشة من جديد",
        "content2_4": "4. شروط النشاط اليومية كالتالي:",
        "content2_4_1": "أ.الوقت على الإنترنت خلال ال٧ أيام الماضية أكثر من",
        "content2_4_2": ". الدردشة معهم بنشاط خلال ال٧ أيام الماضية أكثر من ",
        "content2_4_3": "س. تم تسجيل الدخول إلى التطبيق في اليوم السابق",
        "content3_1": "يتم حساب معدل الدردشة استنادًا إلى شعبيتك في سيناريوهات الدردشة الخاصة. هذا يشمل:",
        "content3_2": "1. التعرض. كم عدد الأشخاص الذين رأوك على الصفحة الرئيسية، في الخلاصة، أو على صفحة ملفك الشخصي",
        "content3_3": "2. مدة الإنترنت. كم من الوقت بقيت في التطبيق",
        "content3_4": "3. عدد الألفة النشطة. كم عدد المستخدمين الذين لديهم ألفة أكبر من ٣ قاموا بإرسال رسائل إليك في اليوم",
        "content3_5": "4. معدل استجابة الرسائل. النسبة المئوية لرسائلك التي تلقت ردودًا",
        "content3_6": "5. عدد الأشخاص الذين بدأت معهم دردشات. كم عدد المستخدمين الذين قمت بتحيتهم أو إرسال رسائل إليهم",
        "content3_7": "6. معدل الاستجابة الفعالة. معدل الرد على رسائل الآخرين خلال فترة زمنية صالحة",
        "content3_8": "7. متوسط استهلاك الماس الدردشة. كم عدد الماسات التي أنفقها الأشخاص في الدردشة معك، بما في ذلك الدردشة وهدايا 1v1",
        "content3_9": "8. عدد الأشخاص الذين أنفقوا الماس في المحادثات. كم عدد الأشخاص الذين أنفقوا الماس أثناء الدردشة معك، بما في ذلك الدردشة وهدايا 1v1",
        "content4_1": "يمكنك الاطلاع على القيم الإحصائية لبياناتك المتنوعة ومقارنتها بتلك الخاصة بـ Layla Idols في صفحة تفاصيل نقاط الدردشة. سيؤدي النقر فوق النصائح ",
        "content4_2": "الخاصة بالعناصر المقابلة إلى إرشادك حول كيفية تحسين هذه القيم",
        "header1": "و Layla Idol",
        "header2": "كيف تصبحين Layla Idol",
        "header3": "ما هو معدل الدردشة",
        "header4": "- كيف ارتفاع نقاط الدردشة ",
        "minute": " دقيقة.",
        "people": "",
        "title": "مساعدة Layla Idol"
    },
    "inviteCash": {
        "COPY": "نسخ ",
        "Copy_successfully": "تم النسخ بنجاح ",
        "title": "الدعوة لكسب المال "
    },
    "Level": {
        "avatarFrame": "إطار الصورة ",
        "badgeReward": "وسام المكافأة",
        "charmLevel": "مستوى السحر",
        "currentExp": "قيمة الخبرة الخالية ",
        "entranceEffect": "دخول بالمؤثرات ",
        "familyLevel": "مستوى العشيرة ",
        "familyLeveldata": [
            "المستوى",
            "المساهمة المطلوبة",
            "عدد الأعضاء",
            "الهوية"
        ],
        "gradePrivileges": "مستوى الامتيازات ",
        "identity": [
            "نائب العشيرة",
            "شيخ العشيرة"
        ],
        "personLevel": "المستوى الشخصي",
        "stillNeed": "يحتاج",
        "title": "المستوى",
        "upgrade": "كيفية الترقية؟ ",
        "upgradebox": {
            "charm": [
                {
                    "title": "إستلام الهدية",
                    "value": " 1كوينز = 1قيمة جاذبية "
                }
            ],
            "family": [
                {
                    "title": "تم إكمال مهمات العشيرة ",
                    "value": "الحصول على مساهمة العشيرة "
                }
            ],
            "person": [
                {
                    "title": "إرسال هدية ",
                    "value": "1ماسة= 1 خبرة شخصية "
                },
                {
                    "title": "تم إكمال المهمات",
                    "value": "الحصول على خبرة شخصية"
                }
            ],
            "wealth": [
                {
                    "title": "إرسال هدية ",
                    "value": "1 ماسة = 1 خبرة الثروة"
                },
                {
                    "title": "تم إكمال المهمات",
                    "value": "الحصول على خبرة الثروة "
                }
            ]
        },
        "wealthLevel": "المستوى الشخصي"
    },
    "nation": {
        "ae": "الإمارات العربية المتحدة",
        "ao": "أنغولا",
        "ar": "الأرجنتين",
        "at": "النمسا",
        "au": "أستراليا",
        "bd": "بنغلاديش",
        "bh": "البحرين",
        "br": "البرازيل",
        "ca": "كندا",
        "cl": "تشيلي",
        "cn": "الصين",
        "de": "ألمانيا",
        "dz": "الجزائر",
        "eg": "مصر",
        "es": "إسبانيا",
        "fr": "فرنسا",
        "gb": "بريطانيا",
        "hk": "هونغ كونغ",
        "il": "إسرائيل",
        "iq": "العراق",
        "ir": "إيران",
        "it": "إيطاليا",
        "jo": "الأردن",
        "jp": "اليابان",
        "kw": "الكويت",
        "lb": "لبنان",
        "lv": "لاتفيا",
        "ly": "ليبيا",
        "ma": "المغرب",
        "mx": "المكسيك",
        "my": "ماليزيا",
        "nl": "هولندا",
        "om": "سلطنة عمان",
        "ph": "الفلبين",
        "pk": "باكستان",
        "ps": "فلسطين",
        "pt": "البرتغال",
        "qa": "دولة قطر",
        "ru": "روسيا",
        "sa": "المملكة العربية السعودية",
        "sd": "السودان",
        "sy": "سوريا",
        "tn": "تونس",
        "tr": "تركيا ",
        "tw": "تايوان",
        "us": "الولايات المتحدة الأمريكية",
        "ye": "اليمن"
    },
    "nobilityLevel": {
        "diamond": "استهلاك الألماس",
        "head": "مستوى النبيلة",
        "title": "قواعد رتب النبلاء",
        "txt1": "1. بدءًا من الساعة 0:00 في اليوم الأول من كل شهر طبيعي (UTC+8)، سيقوم النظام تلقائيًا بحساب نقاط الخبرة الخاصة بالمستخدم وإعادة مطابقة مستوى النبيل الخاص بالمستخدم وفقًا لنقاط الخبرة في الجدول التالي.",
        "txt2": "2. كل ماسة معاد شحنها = نقطة خبرة واحدة.",
        "txt3": "3. حساب نقاط الخبرة = نقاط خبرة الشهر الحالي + نقاط خبرة الشهر الماضي.",
        "txt4": "I. بعد تحقيق الرتبة النبيلة المقابلة، ستظل الرتبة النبيلة والامتيازات سارية حتى نهاية الشهر التالي. على سبيل المثال، إذا وصل المستخدم إلى المستوى 4 في 17 أكتوبر، فستستمر رتبة المستوى 4 حتى نهاية نوفمبر.",
        "txt5": "II. ضمن فترة الشهر الطبيعي الحالي، إذا تحقق المستخدم لرتبة نبيلة أعلى بعد الوصول إلى واحدة أدنى، سيحصل مباشرة على رتبة النبيلة الأعلى، وستستمر الرتبة المقابلة حتى نهاية الشهر التالي. على سبيل المثال، إذا وصل المستخدم إلى المستوى 2 في 17 أكتوبر، ثم وصل إلى المستوى 4 في 19 أكتوبر، سيحصل مباشرة على رتبة المستوى 4، والتي ستستمر حتى نهاية نوفمبر.",
        "txt6": "III. بعد انتهاء الشهر الطبيعي الحالي، يمكن لمستوى VIP الاحتفاظ بما تم تحقيقه في الشهر السابق، ولكن تبدأ تراكم نقاط VIP من الصفر في الشهر التالي. على سبيل المثال، إذا وصل المستخدم إلى المستوى 4 في أكتوبر، واحتفظ بالمستوى 4 في نوفمبر، ووصل إلى المستوى 2 فقط بنهاية ديسمبر، فسيستمتع فقط بامتيازات المستوى 2 ابتداءً من الأول من ديسمبر.",
        "txt7": "المستوى",
        "txt8": "نقاط الخبرة المطلوبة",
        "txt9": "نقاط الخبرة المطلوبة من المستوى السابق إلى هذا المستوى"
    },
    "patricianDescription": [
        {
            "title": "1. ماهي الأرستقراطية؟ ",
            "vlaue": [
                "الأرستقراطية هي اشارة للمكانة المستخدمين في التطبيق وتنقسم حاليًا إلى ستة مستويات.",
                " ويتمتع الأرستقراطيون من مختلف المستويات بامتيازات حصرية، ويمكنك الحصول على الأرستقراطية والاستمتاع بالامتيازات الحصرية لها من خلال إعادة الشحن ثم التفعيل والتجديد كل شهر. "
            ]
        },
        {
            "title": "2. قواعد الشراء",
            "vlaue": [
                "للحصول على امتيازات الأرستقراطية 1 و 2 و 3 ، يمكنك تفعيلها عن طريق إعادة الشحن مباشرة.",
                "أما بالنسبة للأرستقراطية 4 و5 و 6، فأنت بحاجة إلى شرائها بالماس",
                "بعد التفعيل بنجاح ، ستزيد أيام الأرستقراطية المحددة تلقائيًا بمقدار 31 يومًا / شهر.",
                "إذا تم تفعيل العديد من الأرستقراطيات من مستويات مختلفة في نفس الوقت ، فسيتم استهلاك أولاً أيام المتبقية من الأرستقراطية الأعلى ، وستظل أيام الأرستقراطية ذوي المستوى المنخفض كما هي."
            ]
        },
        {
            "title": "3. قواعد التجديد",
            "vlaue": [
                "بعد نجاح التفعيل ، إذا قمت بالتجديد خلال فترة الصلاحية ، يمكنك الاستمتاع بخصم التجديد. ولكن إذا قمت بإعادة التفعيل بعد تاريخ انتهاء الصلاحية ، فلن تتمتع بخصم التجديد.",
                "يمكنك تفعيل أي مستوى من الأرستقراطيات للحصول على المكافأة الماس اليومية لتسجيل الدخول اليومي ، وتحتاج إلى جمع المكافأة بنفسك لهذه المهمة، وإذا لم يتم الاستلام بالوقت المحدد يعني أنك تتخلى عن هذه المكافآت "
            ]
        }
    ],
    "patricianDescriptiontitle": "شرح الأرستقراطية",
    "raveParty": {
        "activityIntroduction1": " \" 🎉مبروك، ليلى أعدت لك حفلة كرنفال خاصة بك. 💫أكمل تارجيت الشحن و الاستهلاك للحصول على النقاط ، والتي يمكن استخدامها لاسترداد الجوائز! 🎁",
        "activityIntroduction2": "بالإضافة إلى ذلك، سيحصل أفضل 10 مستخدمين لديهم أكبر عدد من الشحن و الاستهلاك على مكافآت امتياز إضافية. 🏆هناك العديد من المكافآت، انضم الآن! 💰✨\"",
        "benefits": "افتح المكافآت والفوائد الحصرية",
        "consumedEvery": "تحصل على 20 نقطة مقابل كل 16,000 جوهرة يتم إنفاقها على الهدايا المحظوظة.",
        "consumptionAmounts": "كمية الاستهلاك",
        "consumptionList": "قائمة الاستهلاك",
        "customizedAvatarFrame3": "إطار الصورة الرمزية المخصص<br/>(3 أيام)",
        "customizedGift3": "هدية مخصصة <br/>(3 أيام)",
        "customizedLaunch15": "إطلاق مخصص <br/>(15 يوم) ",
        "customizedLaunch3": "مدخل مخصص <br/>(3 أيام)",
        "customizedLaunch7": "لافتة مخصصة <br/>(7 أيام)",
        "details": "التفاصيل",
        "diamonds": "الماس .",
        "duringTheEvent": "ترتيب حسب كمية الماس التي تنفق على هدايا الحظ خلال الحدث",
        "exchange": "مبادلة",
        "leaderboardReward": "أعلى جائزة",
        "leaderboardReward1": "1 - خلال فترة النشاط ، المستخدم شحن سوف تدرج في قائمة الأنشطة . أعلى 10 المستخدمين في الرسوم البيانية والرسوم البيانية والرسوم البيانية والرسوم البيانية الاستهلاكية سوف تحصل على امتيازات إضافية . يرجى ملاحظة أن هدية الجائزة صالحة لمدة 7 أيام . يرجى استخدامها خلال فترة الصلاحية ",
        "leaderboardReward2": "2 - يرجى ملاحظة : يمكن الحصول على 20 نقطة لكل 2100 الماس القابلة لإعادة الشحن",
        "leaderboardReward3": "3 - جميع الجوائز سيتم تسليمها إلى حسابك في غضون 3-5 أيام عمل بعد الحدث ",
        "leaderboardReward4": "4 - أعلى 10 من اثنين من الرسوم البيانية ، يرجى إضافة تفاصيل الاتصال الخاصة بنا للحصول على جائزة حصرية",
        "luckyGiftsRules": "محظوظ هدية القاعدة",
        "luckyGiftsRules1": "1 - فقط محظوظ الهدايا المدرجة في قائمة الاستهلاك",
        "luckyGiftsRules2": "2 - إرسال هدية محظوظة ، فرصة للفوز بالجائزة الكبرى ",
        "luckyGiftsRules3": "3 - المزيد من الهدايا التي تعطى في مجموعات ، والمزيد من فرص الفوز بالجائزة الأولى ",
        "luckyGiftsRules4": "4 - الجائزة هي الماس التي سيتم إرسالها إلى حقيبة الخاص بك ",
        "luckyGiftsRules5": "5 - مكافأة متعددة هي متعددة من سعر الوحدة هدية ",
        "luckyGiftsRules6": "6 - مرساة يتلقى هدية محظوظة فقط 10 ٪ من قيمة الهدية ",
        "luckyGiftsRules7": "7 - هدية مع علامة البرسيم في الزاوية اليمنى العليا هو هدية محظوظة",
        "luckyGiftsRulesNote": "ملاحظة :",
        "luckyGiftsRulesNote1": "1 - جائزة المستخدم في الحظ هدية النشاط يمكن استخدامها فقط على منصة ليلى ، لا يمكن استخدامها في أي نشاط مربح ",
        "luckyGiftsRulesNote2": "2 - تحتفظ ليلى بالحق في التفسير النهائي",
        "luckyGiftsRulesTitle": " المادة :",
        "noRanking": " لا توجد بيانات للتصنيف متاحة.：",
        "ok": "حسناً",
        "points": "لا يتجزأ",
        "points1": "مجموع استهلاك { ",
        "pointsReward": " يمكنك الحصول على نقاط مكافأة حصرية من خلال إعادة شحن ! تحقق من المكافآت في منطقة الصرف أدناه ! يمكنك الحصول على نقاط من خلال إعادة شحن المبلغ التراكمي من الماس . يرجى ملاحظة أن الجائزة صالحة . مدة الجائزة تحسب من لحظة الفداء . يرجى تغيير قبل 23 : 59 بالتوقيت العالمي + 8 في 3 نيسان / أبريل . خلاف ذلك ، فإن النتيجة سوف تنتهي . س",
        "pointsReward2": "يرجى ملاحظة أن الجائزة صالحة . مدة الجائزة تحسب من لحظة الفداء .",
        "pointsReward3": "يرجى تغيير قبل 6 مايو 23 : 59 بالتوقيت العالمي + 8 . خلاف ذلك ، فإن النتيجة سوف تنتهي .",
        "pointsRewardTitle": "نقاط مكافأة",
        "privilege": "امتيازات",
        "rank": "درجة",
        "rankingBased": "ترتيب حسب كمية الماس التي يتم شحنها خلال فترة النشاط",
        "recharge": "مشحونة",
        "rechargeList": "قائمة شحن",
        "redeemFailed": "فشل التحويل",
        "redeemSuccessful": "نجاح التحويل",
        "remainingPoints": "النقاط المتبقية",
        "rewards": "مكافأة",
        "rules": "قواعد",
        "telegram": "برقية:",
        "telegramNum": "+852 6040 9424",
        "title": "كرنفال الربيع",
        "top1": "top1",
        "top2": "top2",
        "top3": "top3",
        "top46": "top4-6",
        "top710": "top7-10",
        "totalConsumption": "} نقطة",
        "tryAgain": "نقاط التحويل غير كافية ، يرجى تأكيد وحاول مرة أخرى",
        "WhatsApp": "رقم الواتس اب الخاص بنا: ",
        "WhatsAppNumber": "+86 184 3591 9081"
    },
    "transfer": {
        "contact_content1": "وكيل الشحن",
        "contact_content10": "سجل التحويلات",
        "contact_content11": "إدخال الأرقام",
        "contact_content12": "رصيدي بالماس",
        "contact_content13": "فشلت عملية التحويل ، رصيدك من الماس غير كافٍ",
        "contact_content14": "ضبط كلمة المرور للتحويل",
        "contact_content15": "كلمة المرور للتحويل",
        "contact_content16": "قم بإدخال كلمة المرور",
        "contact_content17": "من أجل سهولة عملية التحويل الخاصة بك ، يرجى تذكر كلمة المرور هذه",
        "contact_content18": "تأكيد كلمة المرور لا تتطابق مع كلمة المرور التي تم إدخالها في الخطوة السابقة ، يرجى المحاولة مرة أخرى",
        "contact_content19": "تأكيد كلمة المرور للتحويل",
        "contact_content2": "التواصل مع وكيل الشحن",
        "contact_content20": "تأكيد",
        "contact_content21": "تم تجميد عملة التحويل الخاصة بك ، يرجى الاتصال بالمسؤول",
        "contact_content22": "تأكيد التحويل",
        "contact_content23": "المرسل",
        "contact_content24": "مبلع الحولة",
        "contact_content25": "تأكيد التحويل",
        "contact_content26": "نسيت كلمة المرور؟ اتصل بالمسؤول لإعادة الضبط",
        "contact_content27": "كلمة المرور خطأ. يرجى المحاولة مرة اخري",
        "contact_content28": "تم التحويل",
        "contact_content29": "دليل التحويلات",
        "contact_content3": "نسخ",
        "contact_content30": "لا يوجد سجل دليل التحويلات",
        "contact_content31": "لا يوجد سجل التحويلات",
        "contact_content32": "تم إعداد كلمة السر الخاصة بالتحويل بنجاح، الرجاء تذكر ذلك.",
        "contact_content33": "تحويل",
        "contact_content34": "تفاصيل الماس",
        "contact_content35": "رصيد الماس",
        "contact_content36": "يجب ألا تقل كلمة المرور عن أربعة أرقام",
        "contact_content4": "إرسال",
        "contact_content5": "أدخل حساب الطرف الآخر",
        "contact_content6": "سيتم إضافة الماس المحول إلى حساب الطرف الآخر ولا يمكن إرجاعه بعد الأرسال ؛ يرجى التأكد قبل الأرسال.",
        "contact_content7": "أدخل ايدي الحساب",
        "contact_content8": "التالي",
        "contact_content9": "ايدي الحساب خاطئ ،المستخدم ليس موجود بالنظام",
        "error": "فشل اتصال الشبكة.",
        "finished": "كل تحميل",
        "Loading": "تحميل المزيد من البيانات ..."
    },
    "eventStartsIn": "",
    "nivi_idolHelp": {
        "content1": "Nivi Idol هو تصنيف خاص يمنحه تطبيق Nivi للفتيات المشهورات. أن تصبحي Nivi Idol يأتي مع امتيازات متعددة، بما في ذلك شارة خاصة، تعرض أكثر، ورسائل مطابقة أكثر.",
        "content2_1": "1. أولاً، يجب أن تجتازي التحقق من الشخص الحقيقي. ثانياً، يجب أن يصل معدل الدردشة الخاص بك إلى ٨٠ نقطة",
        "content2_2": "2. استوفي شروط النشاط في اليوم، وسيتم حساب معدل الدردشة في الساعة ٤ صباحًا (UTC+8) في اليوم التالي استنادًا إلى بيانات الأيام ال٧ الماضية",
        "content2_3": "3. إذا لم تتم مقابلة شروط النشاط في اليوم، سيعاد تعيين معدل الدردشة إلى ٠ في اليوم التالي. العودة للنشاط مرة أخرى ستبدأ تراكم وحساب معدل الدردشة من جديد",
        "content2_4": "4. شروط النشاط اليومية كالتالي:",
        "content2_4_1": "أ.الوقت على الإنترنت خلال ال٧ أيام الماضية أكثر من",
        "content2_4_2": ". الدردشة معهم بنشاط خلال ال٧ أيام الماضية أكثر من",
        "content2_4_3": "س. تم تسجيل الدخول إلى التطبيق في اليوم السابق",
        "content3_1": "يتم حساب معدل الدردشة استنادًا إلى شعبيتك في سيناريوهات الدردشة الخاصة. هذا يشمل:",
        "content3_2": "1. التعرض. كم عدد الأشخاص الذين رأوك على الصفحة الرئيسية، في الخلاصة، أو على صفحة ملفك الشخصي",
        "content3_3": "2. مدة الإنترنت. كم من الوقت بقيت في التطبيق",
        "content3_4": "3. عدد الألفة النشطة. كم عدد المستخدمين الذين لديهم ألفة أكبر من ٣ قاموا بإرسال رسائل إليك في اليوم",
        "content3_5": "4. معدل استجابة الرسائل. النسبة المئوية لرسائلك التي تلقت ردودًا",
        "content3_6": "5. عدد الأشخاص الذين بدأت معهم دردشات. كم عدد المستخدمين الذين قمت بتحيتهم أو إرسال رسائل إليهم",
        "content3_7": "6. معدل الاستجابة الفعالة. معدل الرد على رسائل الآخرين خلال فترة زمنية صالحة",
        "content3_8": "7. متوسط استهلاك الماس الدردشة. كم عدد الماسات التي أنفقها الأشخاص في الدردشة معك، بما في ذلك الدردشة وهدايا 1v1",
        "content3_9": "8. عدد الأشخاص الذين أنفقوا الماس في المحادثات. كم عدد الأشخاص الذين أنفقوا الماس أثناء الدردشة معك، بما في ذلك الدردشة وهدايا 1v1",
        "content4_1": "يمكنك الاطلاع على القيم الإحصائية لبياناتك المتنوعة ومقارنتها بتلك الخاصة بـ Nivi Idols في صفحة تفاصيل نقاط الدردشة. سيؤدي النقر فوق النصائح",
        "content4_2": "الخاصة بالعناصر المقابلة إلى إرشادك حول كيفية تحسين هذه القيم",
        "header1": "و Nivi Idol",
        "header2": "كيف تصبحين Nivi Idol",
        "header3": "ما هو معدل الدردشة",
        "header4": "- كيف ارتفاع نقاط الدردشة",
        "minute": "دقيقة.",
        "people": "",
        "title": "مساعدة Nivi Idol"
    }
}