import Vue from 'vue';
import VueRouter from 'vue-router';

//解决重复路由
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

const routes = [
  {
    path: '/privacy',
    name: 'privacy',
    // component: () => import('../views/privacy.vue'),
    component: resolve => require(['../views/privacy.vue'], resolve)
  },
  {
    path: '/service',
    name: 'service',
    // component: () => import('../views/service.vue'),
    component: resolve => require(['../views/service.vue'], resolve)

  },
  {
    path: '/familyDescription',
    name: 'familyDescription',
    // component: () => import('../views/familyDescription.vue'),
    component: resolve => require(['../views/familyDescription.vue'], resolve)

  },
  {
    path: '/inviteCash',
    name: 'inviteCash',
    // component: () => import('../views/inviteCash.vue'),
    component: resolve => require(['../views/inviteCash.vue'], resolve)

  },
  {
    path: '/inviteCashDescription',
    name: 'inviteCashDescription',
    // component: () => import('../views/inviteCashDescription.vue'),
    component: resolve => require(['../views/inviteCashDescription.vue'], resolve)

  },
  {
    path: '/bindAccount',
    name: 'bindAccount',
    // component: () => import('../views/bindAccount.vue'),
    component: resolve => require(['../views/bindAccount.vue'], resolve)

  },
  {
    path: '/personalLevel',
    name: 'personalLevel',
    // component: () => import('../views/personalLevel.vue'),
    component: resolve => require(['../views/personalLevel.vue'], resolve)

  },
  {
    path: '/newPersonalLevel',
    name: 'newPersonalLevel',
    // component: () => import('../views/personalLevel.vue'),
    component: resolve => require(['../views/newPersonalLevel.vue'], resolve)

  },
  {
    path: '/familyLevel',
    name: 'familyLevel',
    // component: () => import('../views/familyLevel.vue'),
    component: resolve => require(['../views/familyLevel.vue'], resolve)

  },
  {
    path: '/patricianDescription',
    name: 'patricianDescription',
    // component: () => import('../views/patricianDescription.vue'),
    component: resolve => require(['../views/patricianDescription.vue'], resolve)

  },
  {
    path: '/familyIncome',
    name: 'familyIncome',
    // component: () => import('../views/familyIncome/familyIncome.vue'),
    component: resolve => require(['../views/familyIncome/familyIncome.vue'], resolve)

  },
  {
    path: '/transfer',
    name: 'transfer',
    // component: () => import('../views/transferMoney/transfer.vue'),
    component: resolve => require(['../views/transferMoney/transfer.vue'], resolve)

  },
  // reseller相关
  {
    path: '/transferMoney',
    name: 'transferMoney',
    // component: () => import('../views/transferMoney/index.vue'),
    component: resolve => require(['../views/transferMoney/index.vue'], resolve)

  },
  {
    path: '/addressBook',
    name: 'addressBook',
    // component: () => import('../views/transferMoney/addressBook.vue'),
    component: resolve => require(['../views/transferMoney/addressBook.vue'], resolve)

  },
  {
    path: '/record',
    name: 'record',
    // component: () => import('../views/transferMoney/record.vue'),
    component: resolve => require(['../views/transferMoney/record.vue'], resolve)

  },
  {
    path: '/eventRewards',
    name: 'eventRewards',
    component: resolve => require(['../views/eventRewards.vue'], resolve)

  },
  {
    path: '/listRules',
    name: 'listRules',
    component: resolve => require(['../views/listRules.vue'], resolve)

  },
  {
    path: '/diamondDescription',
    name: 'diamondDescription',
    component: resolve => require(['../views/diamondDescription.vue'], resolve)

  },
  {
    path: '/nobilityLevel',
    name: 'nobilityLevel',
    component: resolve => require(['../views/nobilityLevel.vue'], resolve)

  },
  {
    path: '/nobilityLevel_nivi',
    name: 'nobilityLevel_nivi',
    component: resolve => require(['../views/nobilityLevel_nivi.vue'], resolve)

  },
  // 图片转url页面
  {
    path: '/mediaPage',
    name: 'mediaPage',
    component: resolve => require(['../views/mediaPage/index.vue'], resolve),
  },
  // Layla Idol Help页面
  {
    path: '/idolHelp',
    name: 'idolHelp',
    component: resolve => require(['../views/idolHelp/index.vue'], resolve),

  },
  // nivi Idol Help页面
  {
    path: '/idol_help_nivi',
    name: 'idolHelp_nivi',
    component: resolve => require(['../views/idolHelp_nivi/index.vue'], resolve),

  },
  // 狂欢派对
  {
    path: '/raveParty',
    name: 'raveParty',
    component: resolve => require(['../views/raveParty/index.vue'], resolve),

  },
  // 主播活动
  {
    path: '/anchorActive',
    name: 'anchorActive',
    component: resolve => require(['../views/anchorActive/index.vue'], resolve),

  },
  // lucky Poker活动
  {
    path: '/luckyPoker',
    name: 'luckyPoker',
    component: resolve => require(['../views/luckyPoker/index.vue'], resolve),

  },
  // zainCash
  {
    path: '/namePhone',
    name: 'namePhone',
    component: resolve => require(['../views/namePhone/index.vue'], resolve),

  },
  // 仲夏梦之夜
  {
    path: '/dreamNight',
    name: 'dreamNight',
    component: resolve => require(['../views/dreamNight/index.vue'], resolve),

  },
  // pk活动
  {
    path: '/pkTournamentPro',
    name: 'pkTournamentPro',
    component: resolve => require(['../views/pkTournamentPro/index.vue'], resolve),
  },
  // pk活动2.0
  {
    path: '/pkTournamentProV2',
    name: 'pkTournamentProV2',
    component: resolve => require(['../views/pkTournamentProV2/index.vue'], resolve),
  },
  // pk活动3.0
  {
    path: '/pkTournamentProV3',
    name: 'pkTournamentProV3',
    component: resolve => require(['../views/pkTournamentProV3/index.vue'], resolve),
  },
  // pk活动4.0
  {
    path: '/pk_tournament_pro_v4',
    name: 'pkTournamentProV4',
    component: resolve => require(['../views/pkTournamentProV4/index.vue'], resolve),
  },
  // pk活动5.0
  {
    path: '/pk_tournament_pro_v5',
    name: 'pkTournamentProV5',
    component: resolve => require(['../views/pkTournamentProV5/index.vue'], resolve),
  },
  // 土耳其国庆日活动
  {
    path: '/turkeyDay',
    name: 'turkeyDay',
    component: resolve => require(['../views/turkeyDay/notTurkey.vue'], resolve),

  },
  // 非土耳其国庆日活动
  {
    path: '/notTurkeyDay',
    name: 'notTurkeyDay',
    component: resolve => require(['../views/turkeyDay/index.vue'], resolve),

  },
  // 厅战活动
  {
    path: '/hallBattle',
    name: 'hallBattle',
    component: resolve => require(['../views/hallBattle/index.vue'], resolve),

  },
  // 补档活动
  {
    path: '/duplicates',
    name: 'duplicates',
    component: resolve => require(['../views/duplicates/index.vue'], resolve),

  },
  // roomCup活动
  {
    path: '/roomCup',
    name: 'roomCup',
    component: resolve => require(['../views/roomCup/index.vue'], resolve),
  },
  // 双旦活动
  {
    path: '/trUsa',
    name: 'trUsa',
    component: resolve => require(['../views/trUsa/index.vue'], resolve),

  },
  // legend
  {
    path: '/legend',
    name: 'legend',
    component: resolve => require(['../views/legend/index.vue'], resolve),

  },
  // 补档活动
  {
    path: '/duplicates_v2',
    name: 'duplicates_v2',
    component: resolve => require(['../views/duplicates_v2/index.vue'], resolve),

  },
  // 2025情人节活动
  {
    path: '/cp_event',
    name: 'cp_event',
    component: resolve => require(['../views/cpEvent/index.vue'], resolve),
  },
  // 2025情人节活动规则
  {
    path: '/cp_event_rewards',
    name: 'cp_event_rewards',
    component: resolve => require(['../views/cpEvent/rewards.vue'], resolve),
  },
  // legend_v2
  {
    path: '/legend_v2',
    name: 'legend_v2',
    component: resolve => require(['../views/legend_v2/index.vue'], resolve),

  },
  // 补档活动
  {
    path: '/duplicates_v3',
    name: 'duplicates_v3',
    component: resolve => require(['../views/duplicates_v3/index.vue'], resolve),

  },
  // roomCup活动 TR区
  {
    path: '/roomCup_tr',
    name: 'roomCup_tr',
    component: resolve => require(['../views/roomCupTr/index.vue?v=1.0.2'], resolve),
  },
  // 阿语斋月签到活动
  {
    path: '/sign_in',
    name: 'sign_in',
    component: resolve => require(['../views/signIn/index.vue?v=1.0.6'], resolve),
  },
  // 跳转房间页面
  {
    path: '/to_room',
    name: 'to_room',
    component: resolve => require(['../views/toRoom/index.vue?v=2.0.4'], resolve),
  },
  // 礼物收集 阿语
  {
    path: '/save_gift_ar',
    name: 'save_gift_ar',
    component: resolve => require(['../views/saveGiftAr/index.vue?v=1.2.1'], resolve),
  },
  // 礼物收集 土语
  {
    path: '/save_gift_tr',
    name: 'save_gift_tr',
    component: resolve => require(['../views/saveGiftTr/index.vue?v=1.0.0'], resolve),
  },
  // 2025斋月厅战活动
  {
    path: '/hallBattle_v2',
    name: 'hallBattle_v2',
    component: resolve => require(['../views/hallBattleV2/index.vue?v=1.0.2'], resolve),

  },
  // 直连墨西哥SPEI
  {
    path: '/to_spei',
    name: 'toSpei',
    component: resolve => require(['../views/toSpei/index.vue?v=1.1.0'], resolve),

  },
  // 补档活动
  {
    path: '/duplicates_v4',
    name: 'duplicates_v4',
    component: resolve => require(['../views/duplicates_v4/index.vue?v=1.1.2'], resolve),

  },
  // pk活动6.0 2025年3月阿语PK
  {
    path: '/pk_tournament_pro_v6',
    name: 'pkTournamentProV6',
    component: resolve => require(['../views/pkTournamentProV6/index.vue?v=1.0.1'], resolve),
  },
  // pk活动7.0 2025年3月西语PK
  {
    path: '/pk_tournament_pro_v7',
    name: 'pkTournamentProV7',
    component: resolve => require(['../views/pkTournamentProV7/index.vue?v=1.0.2'], resolve),
  },
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      //切换到新路由时，页面滚动到顶部
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
